<template>

    <div class="flex-1 bg-light-blue-500 dark:bg-gray-700">
        <div class="flex flex-col justify-center sm:py-12 bg-light-blue-500 dark:bg-gray-700 m-auto">
            <div class="relative py-3 sm:max-w-xl sm:mx-auto">
                <div class="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl hidden md:block"></div>
                <div class="relative px-4 py-10 bg-white dark:bg-gray-800 dark:text-white shadow-lg sm:rounded-3xl sm:p-20 m-4 md:m-0">

                    <!-- form -->
                    <div class="flex flex-col space-y-2 sm:w-96">

                        <div class="text-center border-b pb-10 mb-10">
                            <svg class="w-24 h-24 m-auto text-light-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <div>Create a new account</div>
                            <p class="text-red-400">Beta accounts are currently invite only.</p>
                        </div>

                        <div>Invite</div>
                        <input type="text" v-bind:class="[inviteAppearsValid ? 'text-light-blue-700 border-light-blue-500 bg-light-blue-200' : 'bg-transparent border-light-blue-500']" class="border" v-model="invite"/>
                        <div class="h-2"></div>

                        <div>Email</div>
                        <input type="text" class="bg-transparent border border-light-blue-500" v-model="email"/>
                        <div class="h-2"></div>

                        <div>Password</div>
                        <input type="password" class="bg-transparent border border-light-blue-500" autocomplete="false" v-model="password"/>
                        <div class="h-2"></div>

                        <div class="flex items-center justify-between">
                            <span>Retype Password</span>
                            <span v-bind:class="[passwordsMatch ? 'text-green-500' : 'text-red-500']">{{ passwordText }}</span>
                        </div>
                        <input type="password" class="bg-transparent border border-light-blue-500" autocomplete="false" v-model="password2"/>
                        <div class="h-2"></div>

                        <button type="button" class="duration-300 inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-light-blue-600 hover:bg-light-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                                :disabled="disableSubmit"
                                @click="onSignupClicked()">
                            Sign Up
                        </button>

                        <div>{{ status }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Signup.vue",
    inject: ['axios', 'player', 'emitter'],
    components: {},

    data() {
        return {
            invite: this.$route.query.invite,
            email: '',
            password: '',
            password2: '',
            passwordMessage: '',
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.email;
        },

        status () {
            return this.$store.status;
        },

        disableSubmit() {
            return !this.passwordsMatch || !this.inviteAppearsValid;
        },

        passwordsMatch() {
            return this.password === this.password2;
        },

        passwordText() {
            if(!this.password || !this.password2 ) return ''
            else if(this.passwordsMatch) return 'Matched!';
            else return 'Passwords must match.';
        },

        inviteAppearsValid() {
            var regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            var myArray = regex.exec(this.invite);
            return myArray != null;
        }
    },

    mounted() {
        if(this.currentUser) {
            this.$router.push('/songs');
        }
    },

    unmounted() {

    },

    methods: {

        onSignupClicked() {
            const data = {email : this.email, password : this.password, invite:this.invite};
            const options = {params: {}};
            this.$store.dispatch('signup', data)
                .then(() => {
                  this.$store.dispatch('login',data)
                      .then(() => {
                        this.$router.push('/songs')
                      })
                })
                .catch(err => {});
        }
    }
}
</script>

<style scoped>

</style>
