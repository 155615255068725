import { v4 as uuidv4 } from 'uuid';
import { WindChime } from "./instruments/windchime";
import { KeyType, PsConfig } from "./config";
import * as _ from "lodash";
import { PsKey } from "./notes";
import * as Tone from 'tone';
var Player = /** @class */ (function () {
    function Player() {
        this.initted = false;
        this.name = "";
        this.key = 'C';
        this.volume = 0.0;
        this.keyType = KeyType.Major;
        this.myId = Math.floor(Math.random() * 1000000);
        this.websiteId = "";
        this.sourceUrl = "";
        this.instruments = [];
        this.toDispose = [];
        this.mobile = false;
    }
    /*
     * PsData is used for reactive display data used with Vue.js because this player instance
     * is corrupted by the reactive framework, due to audio context entanglements.
     */
    Player.prototype.init = function (name, websiteId, emitter, psData2, mobile) {
        console.debug('Pixelsong player init [' + this.myId + ']');
        this.name = name;
        this.websiteId = websiteId;
        this.sourceUrl = "https://pixelsong.io:7000/realtime/" + this.websiteId + "/" + this.myId;
        this.source = null;
        this.emitter = emitter;
        this.psd2 = psData2;
        this.initted = true; // mark it initted
        this.mobile = mobile;
    };
    Player.prototype.loadConfig = function (config) {
        var _this = this;
        if (!this.initted) {
            throw 'Player is not initialized';
        }
        // Copy the defaults into place with an incoming config file which may be an old version
        _.defaultsDeep(config, new PsConfig(config.name));
        // cleanup any previous loads
        this.name = config.name;
        this.key = config.key;
        this.keyType = config.keyType;
        this.volume = config.volume;
        this.cleanupInstruments();
        this.config = config;
        // Set up the refresh timer
        if (!this.dataRefreshTimer) {
            this.dataRefreshTimer = setInterval(function () { return _this.refreshData(); }, this.psd2.refreshInterval);
        }
        // load the config
        //console.info("Loading player with config", config);
        this.updateKey(config.key, config.keyType);
        this.updateVolume(config.volume);
        config.instruments.forEach(function (i) {
            i.id = uuidv4().toString();
            _this.addInstrumentWithConfig(i);
        });
    };
    // ---------------
    // Instrument CRUD
    // ---------------
    Player.prototype.getInstrumentIds = function () {
        var ids = [];
        this.instruments.forEach(function (i) {
            ids.push(i.id);
        });
        return ids;
    };
    Player.prototype.addInstrumentWithConfig = function (config) {
        var instrument = this.createInstrument(config);
        this.addInstrument(instrument);
    };
    Player.prototype.addInstrument = function (newy) {
        this.instruments.push(newy);
        this.psd2.subscribe(newy.subscription);
        this.config = this.generateConfig();
        this.emitter.emit('player-instrument-added', newy);
    };
    Player.prototype.wireExternalInstrument = function (newy) {
        this.psd2.subscribe(newy.subscription);
        this.emitter.emit('player-instrument-added', newy);
    };
    Player.prototype.copyInstrument = function (ic) {
        ic.subscription.myId = uuidv4().toString();
        return this.createInstrument(ic);
    };
    Player.prototype.createInstrument = function (ic) {
        switch (ic.type) {
            case 'windchime': {
                return new WindChime(ic, this.emitter, this.mobile);
            }
            default: {
                //TODO: create a null instrument type?
                throw ('Could not determine which type of instrument to create for config');
            }
        }
    };
    Player.prototype.swapInstrument = function (old, newy) {
        var index = this.instruments.indexOf(old);
        if (index > -1) {
            console.log('replacing instrument at index', index);
            old.off();
            this.cleanupInstrument(old);
            this.instruments[index] = newy;
            this.psd2.unsubscribe(old.subscription);
            this.psd2.subscribe(newy.subscription);
            this.config = this.generateConfig();
            this.emitter.emit('player-instrument-swapped', { old: old, newy: newy });
        }
        else {
            console.log('cannot find instrument with id', old.id);
        }
    };
    Player.prototype.deleteInstrument = function (toDelete, shouldDispose) {
        var index = this.instruments.indexOf(toDelete);
        if (index > -1) {
            this.instruments.splice(index, 1);
        }
        this.psd2.unsubscribe(toDelete.subscription);
        this.emitter.emit('player-instrument-removed', toDelete);
        if (shouldDispose) {
            this.cleanupInstrument(toDelete);
        }
    };
    // ---------------------
    // Updating key & volume
    // ---------------------
    Player.prototype.updateKey = function (newKey, newType) {
        PsKey.key = newKey;
        PsKey.keyType = newType;
        this.key = newKey;
        this.keyType = newType;
        this.emit('key-updated');
    };
    Player.prototype.updateVolume = function (newVolume) {
        this.volume = newVolume;
        var muted = Tone.Master.mute;
        Tone.Master.volume.value = this.volume;
        Tone.Master.mute = muted;
        this.emit('volume-updated');
    };
    Player.prototype.generateConfig = function () {
        var nc = new PsConfig(this.name);
        nc.key = this.key;
        nc.keyType = this.keyType;
        nc.volume = this.volume;
        nc.instruments = [];
        this.instruments.forEach(function (i) {
            nc.instruments.push(i.generateConfig());
        });
        return nc;
    };
    // -----------------
    // Events & Refresh
    // -----------------
    Player.prototype.emit = function (key) {
        if (this.emitter) {
            this.emitter.emit(key, {});
        }
    };
    Player.prototype.onPixelsongEvent = function (e) {
        //console.debug('onPixelSongEvent',e.data);
        this.gotActivity();
        try {
            var obj = JSON.parse(e.data);
            if (obj.ping) {
                return;
            }
            else {
                this.psd2.onEvent(obj);
            }
        }
        catch (error) {
            console.error('error onPixelsongEvent data', e.data);
        }
    };
    Player.prototype.refreshData = function () {
        this.psd2.refresh();
        this.emit('data-refresh');
    };
    Player.prototype.updateInstrumentSummaries = function () {
        this.instruments.forEach(function (i) {
            i.updateSummary();
        });
    };
    /*  -----------
        Connection
        ----------- */
    Player.prototype.onConnectionError = function (e) {
        this.emitter.emit('error', { title: 'Connection Error', subtitle: 'Could not connect to streaming server' });
        console.error('connection error', e);
    };
    Player.prototype.onConnectionOpen = function () {
        console.info('Pixelsong is connected to event stream.');
        this.emitter.emit('error-remove', { title: 'Connection Error', subtitle: 'Could not connect to streaming server' });
    };
    Player.prototype.connect = function () {
        var _this = this;
        this.gotActivity();
        if (this.source && this.source.readyState == 1) {
            return;
        }
        if (this.source) {
            this.source.close();
            this.source = null;
        }
        console.info('Pixelsong is connecting to event stream [' + this.sourceUrl + ']');
        this.source = new EventSource(this.sourceUrl);
        this.source.addEventListener('message', function (e) { return _this.onPixelsongEvent(e); }, false);
        this.source.addEventListener('error', function (e) { return _this.onConnectionError(e); }, false);
        this.source.addEventListener('open', function () { return _this.onConnectionOpen(); }, false);
    };
    Player.prototype.gotActivity = function () {
        var _this = this;
        if (this.keepAliveTimer != null)
            clearTimeout(this.keepAliveTimer);
        this.keepAliveTimer = setTimeout(function () { return _this.connect(); }, 10000);
    };
    /*  -----------
        Instruments
        ----------- */
    Player.prototype.getInstrument = function (id) {
        var value = null;
        this.instruments.forEach(function (i) {
            if (i.id === id) {
                value = i;
            }
        });
        return value;
    };
    /*  -----------
        Cleanup
        ----------- */
    Player.prototype.cleanup = function () {
        if (this.keepAliveTimer != null)
            clearTimeout(this.keepAliveTimer);
        if (this.source) {
            this.source.close();
            this.source = null;
        }
        if (this.dataRefreshTimer) {
            clearInterval(this.dataRefreshTimer);
            this.dataRefreshTimer = null;
        }
        this.cleanupInstruments();
    };
    Player.prototype.cleanupInstruments = function () {
        var _this = this;
        if (this.instruments.length > 0) {
            console.debug('Cleaning up instruments', this.instruments);
            this.instruments.forEach(function (i) {
                i.cleanup();
                _this.toDispose.push(i);
            });
            this.instruments = [];
            setTimeout(function () {
                _this.disposeInstruments();
            }, 30000);
        }
    };
    Player.prototype.cleanupInstrument = function (i) {
        i.cleanup();
        setTimeout(function () {
            i.dispose();
        }, 30000);
    };
    Player.prototype.disposeInstruments = function () {
        console.debug('Disposing instruments');
        this.toDispose.forEach(function (i) {
            i.dispose();
        });
        this.toDispose = [];
    };
    return Player;
}());
export { Player };
var PsMessage = /** @class */ (function () {
    function PsMessage(name, msg) {
        this.name = name;
        this.msg = msg;
    }
    return PsMessage;
}());
export { PsMessage };
