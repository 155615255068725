import { KeySource, KeyType, NoteGeneratorConfig, NoteType, SpecificNoteGenMode } from "./config";
import { Chord, Key } from "@tonaljs/tonal";
import * as _ from "lodash";
var PsKey = /** @class */ (function () {
    function PsKey() {
    }
    PsKey.key = 'D';
    PsKey.keyType = KeyType.Major;
    return PsKey;
}());
export { PsKey };
var NoteCreator = /** @class */ (function () {
    function NoteCreator() {
    }
    NoteCreator.create = function (c) {
        _.defaultsDeep(c, new NoteGeneratorConfig());
        if (c.specific != SpecificNoteGenMode.Off) {
            return new SpecificNoteGenerator(c);
        }
        else {
            return new TheoryGenerator(c);
        }
    };
    return NoteCreator;
}());
export { NoteCreator };
var NullNoteGenerator = /** @class */ (function () {
    function NullNoteGenerator() {
    }
    NullNoteGenerator.prototype.next = function () { return []; };
    NullNoteGenerator.prototype.getSummary = function () { return ''; };
    NullNoteGenerator.prototype.generateConfig = function () {
        return new NoteGeneratorConfig();
    };
    return NullNoteGenerator;
}());
export { NullNoteGenerator };
/* --------------------
    Theory Generator
   --------------------*/
var TheoryGenerator = /** @class */ (function () {
    function TheoryGenerator(c) {
        this.key = "C";
        this.keySource = c.keySource;
        this.key = c.key;
        this.keyType = c.keyType;
        this.range = c.range;
        this.type = c.type;
        if (!this.range || this.range.length == 0) {
            this.range = [3];
        }
        // Override key/type with the songs global values
        // if (this.keySource == KeySource.Song) {
        //     this.key = PsKey.key;
        //     this.keyType = PsKey.keyType;
        // }
    }
    TheoryGenerator.prototype.getSummary = function () {
        return this.getKey() + ' ' + this.getKeyType() + ' ' + this.type + ' [' + this.range + ']';
    };
    TheoryGenerator.prototype.generateConfig = function () {
        var nc = new NoteGeneratorConfig();
        nc.type = this.type;
        nc.key = this.key;
        nc.keyType = this.keyType;
        nc.range = this.range;
        return nc;
    };
    // Key is either inherited from the song, or overridden with this instance
    TheoryGenerator.prototype.getKey = function () {
        var keyToUse = this.keySource == KeySource.Song ? PsKey.key : this.key;
        return keyToUse;
    };
    // KeyType is either inherited from the song, or overridden with this instance
    TheoryGenerator.prototype.getKeyType = function () {
        var keyTypeToUse = this.keySource == KeySource.Song ? PsKey.keyType : this.keyType;
        return keyTypeToUse;
    };
    TheoryGenerator.prototype.next = function () {
        switch (this.type) {
            case NoteType.Note: {
                return this.getNextNote();
            }
            case NoteType.Chord: {
                return this.getNextChord();
            }
            default: {
                return this.getNextChord();
            }
        }
    };
    TheoryGenerator.prototype.getNextNote = function () {
        var notes = this.getNotes();
        var r = Math.floor(Math.random() * notes.length);
        var note = notes[r];
        note += this.getOctave();
        return [note];
    };
    TheoryGenerator.prototype.getNextChord = function () {
        var chords = this.getChords();
        var r = Math.floor(Math.random() * chords.length);
        var chord = Chord.get(chords[r]);
        var notes = chord.notes;
        for (var i = 0; i < notes.length; i++) {
            notes[i] = notes[i] + this.getOctave();
        }
        return notes;
    };
    TheoryGenerator.prototype.getOctave = function () {
        var r = Math.floor(Math.random() * this.range.length);
        var range = this.range[r];
        return range;
    };
    TheoryGenerator.prototype.getNotes = function () {
        switch (this.getKeyType()) {
            case KeyType.Major: {
                return Key.majorKey(this.getKey()).scale;
            }
            case KeyType.Minor_Natural: {
                return Key.minorKey(this.getKey()).natural.scale;
            }
            case KeyType.Minor_Harmonic: {
                return Key.minorKey(this.getKey()).harmonic.scale;
            }
            case KeyType.Minor_Melodic: {
                return Key.minorKey(this.getKey()).melodic.scale;
            }
            default: {
                return Key.majorKey(this.getKey()).scale;
            }
        }
    };
    TheoryGenerator.prototype.getChords = function () {
        switch (this.getKeyType()) {
            case KeyType.Major: {
                return Key.majorKey(this.getKey()).chords;
            }
            case KeyType.Minor_Natural: {
                return Key.minorKey(this.getKey()).natural.chords;
            }
            case KeyType.Minor_Harmonic: {
                return Key.minorKey(this.getKey()).harmonic.chords;
            }
            case KeyType.Minor_Melodic: {
                return Key.minorKey(this.getKey()).melodic.chords;
            }
            default: {
                return Key.majorKey(this.getKey()).chords;
            }
        }
    };
    return TheoryGenerator;
}());
export { TheoryGenerator };
/* -------------------------
    Specific Note Generator
   -------------------------*/
var SpecificNoteGenerator = /** @class */ (function () {
    function SpecificNoteGenerator(c) {
        this.index = 0;
        this.noteType = NoteType.Note;
        this.mode = c.specific;
        this.specificNotes = c.specificNotes;
        this.specificChords = c.specificChords;
        this.noteType = c.type;
    }
    SpecificNoteGenerator.prototype.getSummary = function () {
        return 'specific notes';
    };
    SpecificNoteGenerator.prototype.generateConfig = function () {
        var nc = new NoteGeneratorConfig();
        nc.specific = this.mode;
        nc.specificNotes = this.specificNotes;
        nc.specificChords = this.specificChords;
        return nc;
    };
    SpecificNoteGenerator.prototype.next = function () {
        switch (this.mode) {
            case SpecificNoteGenMode.Sequential: {
                return this.getNext();
            }
            case SpecificNoteGenMode.Random: {
                return this.getRandom();
            }
            case SpecificNoteGenMode.Combine: {
                return [];
            }
            default: {
                return [];
            }
        }
    };
    SpecificNoteGenerator.prototype.getNext = function () {
        switch (this.noteType) {
            case NoteType.Note: {
                return this.getNextNote();
            }
            case NoteType.Chord: {
                return this.getNextChord();
            }
        }
    };
    SpecificNoteGenerator.prototype.getRandom = function () {
        switch (this.noteType) {
            case NoteType.Note: {
                return this.getRandomNote();
            }
            case NoteType.Chord: {
                return this.getRandomChord();
            }
        }
    };
    SpecificNoteGenerator.prototype.getNextNote = function () {
        if (this.specificNotes) {
            if (this.index == this.specificNotes.length) {
                this.index = 0;
            }
            return [this.specificNotes[this.index++]];
        }
        return [];
    };
    SpecificNoteGenerator.prototype.getNextChord = function () {
        if (this.specificChords) {
            if (this.index == this.specificChords.length) {
                this.index = 0;
            }
            return this.specificChords[this.index++];
        }
        return [];
    };
    SpecificNoteGenerator.prototype.getRandomNote = function () {
        var _a;
        if (this.specificNotes) {
            var i = Math.floor(Math.random() * ((_a = this.specificNotes) === null || _a === void 0 ? void 0 : _a.length));
            return [this.specificNotes[i]];
        }
        return [];
    };
    SpecificNoteGenerator.prototype.getRandomChord = function () {
        if (this.specificChords) {
            var i = Math.floor(Math.random() * this.specificChords.length);
            return this.specificChords[i];
        }
        return [];
    };
    SpecificNoteGenerator.prototype.toString = function () { return 'Base note generator'; };
    return SpecificNoteGenerator;
}());
export { SpecificNoteGenerator };
