<template>

    <div class="flex-1 bg-light-blue-500 dark:bg-gray-700">
        <div class="flex flex-col justify-center sm:py-12 bg-light-blue-500 dark:bg-gray-700 m-auto">
            <div class="relative py-3 sm:max-w-xl sm:mx-auto">
                <div class="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl hidden md:block"></div>
                <div class="relative px-4 py-10 bg-white dark:bg-gray-800 dark:text-white shadow-lg sm:rounded-3xl sm:p-20 m-4 md:m-0">

                    <!-- form -->
                    <div class="flex flex-col space-y-2 sm:w-96">

                        <div class="text-center border-b pb-10 mb-10">
                            <svg class="w-24 h-24 m-auto text-light-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                            <div>Login to your account</div>
                        </div>

                        <div>Email</div>
                        <input type="text" class="bg-transparent border border-light-blue-500" v-model="email" v-on:keypress.enter="onLoginClicked()"/>

                        <div class="h-2"></div>

                        <div>Password</div>
                        <input type="password" class="bg-transparent border border-light-blue-500" v-model="password" v-on:keypress.enter="onLoginClicked()"/>

                        <div class="h-2"></div>

                        <button type="button" class="relative duration-300 inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-light-blue-600 hover:bg-light-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                @click="onLoginClicked()">
                            <span v-if="!loading">Login</span>
                            <div v-if="loading" class="spin">Logging in...</div>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Login.vue",
    inject: ['axios', 'player', 'emitter'],
    components: {},

    data() {
        return {
            email: '',
            password: '',
            loading: false,
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.user;
        },

        status () {
            return this.$store.status;
        },
    },

    mounted() {
      if (!this.currentUser) {
        this.$store.dispatch('relogin')
            .then(() => {this.initialize();})
            .catch((err) =>{this.initialize()});
      } else {this.initialize();}
    },

    unmounted() {

    },

    methods: {
      initialize() {
        if(this.currentUser) {
          this.$router.push('/songs');
        }
      },

      onLoginClicked() {
            const userData = {email : this.email, password : this.password};
            const options = {params: {}};
            this.loading = true;
            this.$store.dispatch('login', userData)
                .then(() => this.$router.push('/songs'))
                .catch(err => {console.error(err)})
                .finally(() => {this.loading = false;})
        }
    }
}
</script>

<style scoped>

</style>
