<template>

    <div class="bg-white dark:bg-gray-900 p-4
                xl:w-1/4 overflow-auto">


        <!-- Events & Sparklines -->
        <div class="grid items-center" style="grid-template-columns: 1fr 100px 50px 50px">

            <!-- Grid layout for event data -->
            <strong class="">Events</strong>
            <Sparkline entryId="all" containerId="spark-all"  :psd2="psd2"></Sparkline>
            <span class="text-right">{{ psd2.all().perSecondPretty }}</span>
            <span class="text-right">{{ psd2.all().count }}</span>

            <span class="my-2 col-span-4 border-b border-gray-300 dark:border-gray-700"></span>




            <span class="flex items-center text-light-blue-500 underline cursor-pointer" v-on:click="showPageloadSubEntries=!showPageloadSubEntries">
                    <svg v-if="!showPageloadSubEntries" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    <svg v-if="showPageloadSubEntries" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    Pageloads
            </span>
            <Sparkline entryId="pageload" containerId="spark-pageloads"  :psd2="psd2"></Sparkline>
            <span class="text-right">{{ psd2.pageloads().perSecondPretty }}</span>
            <span class="text-right">{{ psd2.pageloads().count }}</span>


            <!-- Pageload sub entries -->
            <template v-if="showPageloadSubEntries">
                <span class="flex items-center pl-2 text-light-blue-500 underline cursor-pointer col-span-4" v-on:click="showPageloadPath=!showPageloadPath">
                    <svg v-if="!showPageloadPath" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    <svg v-if="showPageloadPath" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    Paths
                </span>

                    <template v-if="showPageloadPath">
                        <template v-for="item in psd2.pageloadpaths().sortedSubEntries()" :key="item.name" >
                            <span class="pl-8 text-xs">{{ item.name }}</span>
                            <Sparkline entryId="pageload.path" v-bind:subEntryId="item.name" v-bind:containerId="'spark-pageloads'+item.name"  :psd2="psd2"></Sparkline>
                            <span class="text-right">{{ item.perSecondPretty }}</span>
                            <span class="text-right">{{ item.count }}</span>
                        </template>

                    </template>

                <span class="flex items-center pl-2 text-light-blue-500 underline cursor-pointer col-span-4" v-on:click="showPageloadTitle=!showPageloadTitle">
                    <svg v-if="!showPageloadTitle" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    <svg v-if="showPageloadTitle" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    Titles
                </span>

                <template v-if="showPageloadTitle">
                    <template v-for="item in psd2.pageloadtitles().sortedSubEntries()" :key="item.name" >
                        <span class="pl-8 text-xs">{{ item.name }}</span>
                        <Sparkline entryId="pageload.title" v-bind:subEntryId="item.name" v-bind:containerId="'spark-pageloads'+item.name"  :psd2="psd2"></Sparkline>
                        <span class="text-right">{{ item.perSecondPretty }}</span>
                        <span class="text-right">{{ item.count }}</span>
                    </template>
                </template>

            </template>


            <!-- seperator -->
            <span class="my-2 col-span-4 border-b border-gray-300 dark:border-gray-700"></span>



            <span class="flex items-center text-light-blue-500 underline cursor-pointer" v-on:click="showClickSubEntries=!showClickSubEntries">
                    <svg v-if="!showClickSubEntries" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    <svg v-if="showClickSubEntries" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    Clicks
            </span>
            <Sparkline entryId="click" containerId="spark-clicks"  :psd2="psd2"></Sparkline>
            <span class="text-right">{{ psd2.clicks().perSecondPretty }}</span>
            <span class="text-right">{{ psd2.clicks().count }}</span>


            <!-- Click sub entries -->
            <template v-if="showClickSubEntries">
                <span class="flex items-center pl-2 text-light-blue-500 underline cursor-pointer col-span-4" v-on:click="showClickPath=!showClickPath">
                    <svg v-if="!showClickPath" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    <svg v-if="showClickPath" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    Paths
                </span>

                <template v-if="showClickPath">
                    <template v-for="item in psd2.clickpaths().sortedSubEntries()" :key="item.name" >
                        <span class="pl-8 text-xs">{{ item.name }}</span>
                        <Sparkline entryId="click.path" v-bind:subEntryId="item.name" v-bind:containerId="'spark-clicks'+item.name"  :psd2="psd2"></Sparkline>
                        <span class="text-right">{{ item.perSecondPretty }}</span>
                        <span class="text-right">{{ item.count }}</span>
                    </template>
                </template>



                <span class="flex items-center pl-2 text-light-blue-500 underline cursor-pointer col-span-4" v-on:click="showClickTitle=!showClickTitle">
                    <svg v-if="!showClickTitle" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    <svg v-if="showClickTitle" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    Titles
                </span>

                <template v-if="showClickTitle">
                    <template v-for="item in psd2.clicktitles().sortedSubEntries()" :key="item.name" >
                        <span class="pl-8 text-xs">{{ item.name }}</span>
                        <Sparkline entryId="click.title" v-bind:subEntryId="item.name" v-bind:containerId="'spark-clicks'+item.name"  :psd2="psd2"></Sparkline>
                        <span class="text-right">{{ item.perSecondPretty }}</span>
                        <span class="text-right">{{ item.count }}</span>
                    </template>
                </template>



                <span class="flex items-center pl-2 text-light-blue-500 underline cursor-pointer col-span-4" v-on:click="showClickText=!showClickText">
                    <svg v-if="!showClickText" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    <svg v-if="showClickText" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    Text
                </span>

                <template v-if="showClickText">
                    <template v-for="item in psd2.clicktexts().sortedSubEntries()" :key="item.name" >
                        <span class="pl-8 text-xs">{{ item.name }}</span>
                        <Sparkline entryId="click.text" v-bind:subEntryId="item.name" v-bind:containerId="'spark-clicks'+item.name"  :psd2="psd2"></Sparkline>
                        <span class="text-right">{{ item.perSecondPretty }}</span>
                        <span class="text-right">{{ item.count }}</span>
                    </template>
                </template>




                <span class="flex items-center pl-2 text-light-blue-500 underline cursor-pointer col-span-4" v-on:click="showClickId=!showClickId">
                    <svg v-if="!showClickId" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    <svg v-if="showClickId" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    ID
                </span>

                <template v-if="showClickId">
                    <template v-for="item in psd2.clickids().sortedSubEntries()" :key="item.name" >
                        <span class="pl-8 text-xs">{{ item.name }}</span>
                        <Sparkline entryId="click.id" v-bind:subEntryId="item.name" v-bind:containerId="'spark-clicks'+item.name"  :psd2="psd2"></Sparkline>
                        <span class="text-right">{{ item.perSecondPretty }}</span>
                        <span class="text-right">{{ item.count }}</span>
                    </template>
                </template>




                <span class="flex items-center pl-2 text-light-blue-500 underline cursor-pointer col-span-4" v-on:click="showClickTag=!showClickTag">
                    <svg v-if="!showClickTag" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    <svg v-if="showClickTag" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
                    Tag
                </span>

                <template v-if="showClickTag">
                    <template v-for="item in psd2.clicktags().sortedSubEntries()" :key="item.name" >
                        <span class="pl-8 text-xs">{{ item.name }}</span>
                        <Sparkline entryId="click.tag" v-bind:subEntryId="item.name" v-bind:containerId="'spark-clicks'+item.name"  :psd2="psd2"></Sparkline>
                        <span class="text-right">{{ item.perSecondPretty }}</span>
                        <span class="text-right">{{ item.count }}</span>
                    </template>
                </template>

            </template>


            <span class=""></span>
            <span class=""></span>
            <span class="text-right text-xs font-black mt-2">per/s</span>
            <span class="text-right text-xs font-black mt-2">total</span>
        </div>

    </div>

</template>

<script>
import Sparkline from "@/components/Sparkline";
import * as Pixelsong from "@/player/index.js";



export default {
    name: "SideBar",
    components: {Sparkline},
    inject: ['player', 'emitter'],
    props: {
        psd2 : Pixelsong.PsData2,
    },
    data() {
        return {
            showPageloadSubEntries : true,
            showPageloadPath : true,
            showPageloadTitle : false,
            showClickSubEntries : false,
            showClickPath : false,
            showClickTitle : false,
            showClickText : false,
            showClickId : false,
            showClickTag : false,
        }
    },

    mounted() {
    },

    unmounted() {
    },

    methods: {

    }
}
</script>

<style scoped>

</style>
