<template>

    <div>

        <div class="flex items-center space-x-4 cursor-pointer order-1 col-span-2 lg:col-span-3" v-on:click="edit()">
            <svg class="w-8 h-8" height="512pt" viewBox="-46 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="linear0" gradientUnits="userSpaceOnUse" x1="210" x2="210" y1="0" y2="512"><stop offset="0" stop-color="#2af598"/><stop offset="1" stop-color="#009efd"/></linearGradient><path d="m378.347656 136-136.800781-85.820312c2.210937-4.589844 3.453125-9.742188 3.453125-15.179688 0-19.328125-15.671875-35-35-35s-35 15.671875-35 35c0 5.464844 1.253906 10.632812 3.484375 15.242188l-135.851563 85.757812h-42.632812v40h420v-40zm-168.316406-58.367188 93.042969 58.367188h-185.507813zm-100.03125 137.367188h40v297h-40zm80 0h40v257h-40zm-160 1h40v216h-40zm240 0h40v216h-40zm81-1h40v177h-40zm0 0" fill="url(#linear0)"/></svg>
            <div>
                <div class="capitalize font-bold text-light-blue-500 underline">{{ instrument.type }}</div>
                <div>{{ instrument.name }}</div>
            </div>
        </div>

        <!-- summary -->
        <div class="flex items-center space-x-2 order-2 col-span-3 lg:col-span-2 pl-12 lg:pl-0">
            <svg v-if="oscillatorType==='sine'"        class="w-6 h-6 text-green-500" x="0px" y="0px" fill="currentColor" viewBox="0 0 60 60" xml:space="preserve"><path d="M18.9,42.6c-4.8,0-9.9-4-15.1-11.8c-0.5-0.7-0.3-1.6,0.4-2.1c0.7-0.5,1.6-0.3,2.1,0.4c4.9,7.3,9.5,10.9,13.4,10.4 c5.7-0.8,8.9-9.9,8.9-10c0.1-0.4,3.8-11,11.4-12.1c5.2-0.7,10.7,3.2,16.3,11.7c0.5,0.7,0.3,1.6-0.4,2.1c-0.7,0.5-1.6,0.3-2.1-0.4 c-4.9-7.3-9.5-10.9-13.4-10.4c-5.7,0.8-8.9,9.9-8.9,10c-0.1,0.4-3.8,11-11.4,12.1C19.7,42.6,19.3,42.6,18.9,42.6z"/></svg>
            <svg v-if="oscillatorType==='square'"      class="w-6 h-6 text-green-500" x="0px" y="0px" fill="currentColor" viewBox="0 0 60 60" xml:space="preserve"><path d="M29,42.6H11c-1.4,0-2.5-1.1-2.5-2.5v-8.6H5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h4c1.4,0,2.5,1.1,2.5,2.5v8.6h17V19.9 c0-1.4,1.1-2.5,2.5-2.5h17.6c1.4,0,2.5,1.1,2.5,2.5v8.6H55c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5h-4.4c-1.4,0-2.5-1.1-2.5-2.5 v-8.6H31.5v19.7C31.5,41.5,30.4,42.6,29,42.6z M9,31.5L9,31.5L9,31.5z"/></svg>
            <svg v-if="oscillatorType==='triangle'"    class="w-6 h-6 text-green-500" x="0px" y="0px" fill="currentColor" viewBox="0 0 60 60" xml:space="preserve"><path d="M25,49.5c-0.9,0-1.8-0.5-2.2-1.3L22.7,48l-7.8-21.1l-4.9,6.4H5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h3.4l4.5-5.8 c0.5-0.7,1.3-1.2,2.2-1.1c0.9,0,1.7,0.5,2.2,1.3l0.1,0.2l7.6,20.4l7.8-33.1c0.3-1,1.2-1.7,2.3-1.7c1.1,0,2,0.6,2.4,1.6L45,36 l3.9-12.7H55c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5h-3.9l-3.9,12.8l-0.2,0.2c-0.5,0.7-1.4,1.1-2.3,1c-0.9-0.1-1.7-0.7-2-1.6 l-7.7-23.9l-7.7,32.9c-0.3,1-1.2,1.6-2.2,1.7C25.1,49.5,25,49.5,25,49.5z"/></svg>
            <svg v-if="oscillatorType==='sawtooth'"    class="w-6 h-6 text-green-500" x="0px" y="0px" fill="currentColor" viewBox="0 0 60 60" xml:space="preserve"><path d="M29,48.3c-0.8,0-1.5-0.4-2-1L11.5,25.6v3.1c0,1.4-1.1,2.5-2.5,2.5H5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h3.5v-4.1 c0-1.1,0.7-2,1.7-2.4c1-0.3,2.2,0,2.8,0.9l15.4,21.6l0-30c0-1.1,0.7-2.1,1.9-2.4c1.1-0.3,2.2,0.1,2.8,1.1l15.8,20.8v-3 c0-1.4,1.1-2.5,2.5-2.5H55c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5h-3.1v4c0,1.1-0.7,2.1-1.9,2.4c-1.1,0.3-2.2-0.1-2.8-1.1 L31.5,15.7l0,30c0,1.1-0.7,2-1.7,2.4C29.5,48.2,29.2,48.3,29,48.3z"/></svg>
            <svg v-if="oscillatorType==='fatsawtooth'" class="w-6 h-6 text-green-500" x="0px" y="0px" fill="currentColor" viewBox="0 0 60 60" xml:space="preserve"><path d="M29,49.3c-1.1,0-2.2-0.5-2.8-1.5L12.5,28.7c0,1.9-1.6,3.4-3.5,3.4H5c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h2.5v-3.1 c0-1.5,1-2.9,2.4-3.3c1.5-0.5,3,0.1,3.9,1.3l13.6,19.1l0-26.9c0-1.6,1-3,2.6-3.4c1.5-0.4,3,0.2,3.8,1.5l14,18.4v-0.1 c0-1.9,1.6-3.5,3.5-3.5H55c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5h-2.1v3c0,1.6-1,3-2.6,3.4c-1.5,0.4-3-0.2-3.8-1.5l-14-18.4 l0,27.1c0,1.5-1,2.9-2.4,3.3C29.7,49.2,29.3,49.3,29,49.3z"/></svg>
          <span>{{ instrument.summary }}</span>
        </div>


        <!-- listen to event -->
        <div class="flex items-center order-3 col-span-3 lg:col-span-2 pl-12 lg:pl-0">
            <div class="mr-2 text-gray-200 dark:text-gray-800 text-center" :class="{ 'text-green-400 dark:text-green-400' : animateOn }">
                <svg class="w-6 h-6 m-auto" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path></svg>
            </div>
            <span class="" v-bind:title="instrument.listenToDetails">{{ instrument.listenTo }}</span>
        </div>

        <!-- volume -->
        <div class="text-center flex items-center order-4 col-span-3 lg:col-span-2 pl-12 lg:pl-0">
            <div class="flex-1">
                <input type="range" min="-50" max="0" step="1" class="w-full" v-model="volume"/>
                <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                    <div>-50</div>
                    <div>0</div>
                </div>

            </div>
            <input type="text" class="block text-center w-14 ml-2 rounded-md bg-gray-100 text-gray-400 dark:bg-gray-800 border border-gray-300 dark:border-gray-700"
                   v-model="volume"/>
        </div>


        <!-- on/off switch -->
        <div class="order-1 lg:order-5 col-span-1 text-right">
            <label class="switch" id="theme-switch">
                <input id="theme-switch-checkbox" type="checkbox" autocomplete="false" v-model="instrument.isOn" @click="toggleOn()">
                <span class="slider round"></span>
            </label>
        </div>

    </div>

</template>

<script>


export default {
    name: "Instrument",
    inject: ['player','emitter'],
    props: {
        instrumentId: String
    },

    data() {
        return {
            animateOn : false,
            dummy: 1.0,
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.email;
        },

        instrument() {
            return this.player.getInstrument(this.instrumentId);
        },

        volume: {
            // I'm using a variable in the vue data to trigger recomputing this property because
            // I couldn't figure out another way to do it.
            get() {
                return parseFloat(this.instrument.volume) + parseFloat(this.dummy) - parseFloat(this.dummy);
            },
            set(val) {
                this.dummy++;
                this.instrument.setVolume(val);
            }
        },

        oscillatorType() {
            return this.instrument.config.windchime.oscillator.type;
        }
    },

    mounted() {
        this.emitter.on('instrument-trigger-'+this.instrument.id, this.animate);
        this.emitter.on('key-updated', this.onKeyUpdated);
    },

    unmounted() {
        this.emitter.off(this.instrument.listenTo, this.animate);
        this.emitter.off('key-updated', this.onKeyUpdated);
    },

    methods: {
        edit() {
          this.emitter.emit('edit-instrument',this.instrument);
        },

        toggleOn() {
            this.instrument.isOn = !this.isOn;
        },

        changeVolume() {
            //console.log('on change volume', this.volume);
            //this.instrument.setVolume(this.volume);
        },

        animate() {
            this.animateOn = true;
            setTimeout(()=>{
                this.animateOn = false;
            }, 250);
        },

        onKeyUpdated() {
            this.instrument.updateSummary();
        }
    }
}
</script>

<style>

</style>
