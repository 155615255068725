import * as Tone from "tone";
import { DelayConfig, DistortionConfig, InstrumentConfig, ReverbConfig } from "../config";
import * as _ from "lodash";
import { Subscription } from "../data";
var Instrument = /** @class */ (function () {
    function Instrument(config, emitter, mobile) {
        var _this = this;
        this.isOn = true;
        this.volume = 0;
        this.summary = '';
        this.mobile = false;
        this.effects = new Array();
        this.distortionWet = 0.1;
        this.distortion = .5;
        this.delayWet = 1;
        this.delayFeedback = 0.0;
        this.reverbWet = 1;
        this.reverbRoomSize = 1;
        // first, copy defaults of a fresh object into the passed in config
        _.defaultsDeep(config, new InstrumentConfig());
        this.id = config.id;
        this.name = config.name;
        this.type = config.type;
        this.config = config;
        this.emitter = emitter;
        this.listenTo = '';
        this.listenToDetails = '';
        this.mobile = mobile;
        this.isOn = config.isOn;
        this.volume = config.volume;
        this.heardFunction = function (msg) {
            _this.onHeard(msg);
        };
        this.subscription = config.subscription;
        this.subscription.onEvent = this.heardFunction;
        // distortion setup
        if (!config.distortion) {
            config.distortion = new DistortionConfig();
        }
        this.distortionWet = config.distortion.wet;
        this.distortion = config.distortion.distortion;
        // delay setup
        if (!config.delay) {
            config.delay = new DelayConfig();
        }
        this.delayWet = config.delay.wet;
        this.delayTime = config.delay.time;
        this.delayFeedback = config.delay.feedback;
        // reverb setup
        if (!config.reverb) {
            config.reverb = new ReverbConfig();
        }
        this.reverbWet = config.reverb.wet ? config.reverb.wet : 0.0;
        this.reverbRoomSize = config.reverb.roomSize;
        this.createEffects();
        this.chain();
    }
    Instrument.prototype.updateSummary = function () {
        this.summary = this.generateSummary();
        this.listenTo = this.subscription.describe();
        this.listenToDetails = this.subscription.details();
    };
    Instrument.prototype.createEffects = function () {
        this.createDistortion();
        this.createDelay();
        this.createReverb();
    };
    Instrument.prototype.createDistortion = function () {
        if (!this.effectDistortion && !this.mobile) {
            this.effectDistortion = new Tone.Distortion();
            this.updateDistortion(this.distortionWet, this.distortion);
        }
    };
    Instrument.prototype.createDelay = function () {
        if (!this.effectDelay && !this.mobile) {
            this.effectDelay = new Tone.FeedbackDelay(this.delayTime, this.delayFeedback);
            this.updateDelay(this.delayWet, this.delayTime, this.delayFeedback);
        }
    };
    Instrument.prototype.createReverb = function () {
        if (!this.effectReverb && !this.mobile) {
            this.effectReverb = new Tone.JCReverb();
            this.effectReverb.set({ roomSize: this.reverbRoomSize, wet: 0.5 });
            this.updateReverb(this.reverbWet, this.reverbRoomSize);
        }
    };
    Instrument.prototype.connectSynthToChain = function (synth) {
        var nodes = [];
        if (this.effectDistortion) {
            nodes.push(this.effectDistortion);
        }
        if (this.effectDelay) {
            nodes.push(this.effectDelay);
        }
        if (this.effectReverb) {
            nodes.push(this.effectReverb);
        }
        nodes.push(Tone.Master);
        synth.chain.apply(synth, nodes);
    };
    Instrument.prototype.chain = function () {
        this.onEffectChainAltered();
    };
    // --------------
    // Control delay
    // --------------
    Instrument.prototype.isDelayOn = function () {
        return this.delayWet > 0;
    };
    Instrument.prototype.isReverbOn = function () {
        return this.reverbWet > 0;
    };
    Instrument.prototype.updateDistortion = function (newWet, newAmount) {
        this.distortionWet = newWet;
        this.distortion = newAmount;
        if (this.effectDistortion) {
            this.effectDistortion.set({
                wet: newWet,
                distortion: newAmount,
            });
        }
    };
    Instrument.prototype.updateDelay = function (newWet, newTime, newFeedback) {
        //console.info('updating delay',newWet,newTime,newFeedback);
        this.delayWet = newWet;
        this.delayTime = newTime;
        this.delayFeedback = newFeedback;
        if (this.effectDelay) {
            this.effectDelay.set({
                wet: newWet,
                delayTime: newTime,
                feedback: newFeedback,
            });
        }
    };
    // --------------
    // Control reverb
    // --------------
    Instrument.prototype.updateReverb = function (newWet, newRoomSize) {
        this.reverbWet = newWet;
        this.reverbRoomSize = newRoomSize;
        if (this.effectReverb) {
            this.effectReverb.set({
                wet: newWet,
                roomSize: newRoomSize
            });
        }
    };
    Instrument.prototype.generateConfig = function () {
        var nc = new InstrumentConfig();
        nc.id = this.id;
        nc.name = this.name;
        nc.type = this.type;
        nc.listenTo = this.listenTo;
        nc.subscription = new Subscription(this.subscription.name, this.subscription.url, this.subscription.tag, this.subscription.id, this.subscription.text, this.subscription.title);
        nc.isOn = this.isOn;
        nc.volume = this.volume;
        nc.distortion = this.config.distortion;
        nc.delay = this.config.delay;
        nc.reverb = this.config.reverb;
        this.applyConfig(nc);
        return nc;
    };
    Instrument.prototype.onHeard = function (msg) {
        //console.debug('onheard base class',msg);
        this.onHeardImpl(msg);
        // in case anyone wants to know we are triggered (like a ui indicator)
        this.emitter.emit('instrument-trigger-' + this.id, {});
    };
    Instrument.prototype.updateListenTo = function (newVal) {
        //this.emitter.off(this.listenTo, this.heardFunction);
        this.listenTo = newVal;
        this.config.listenTo = newVal;
        //this.emitter.on(this.listenTo, this.heardFunction);
    };
    Instrument.prototype.updateSubscription = function (newSub) {
        this.subscription.name = newSub.name;
        this.subscription.url = newSub.url;
        this.subscription.tag = newSub.tag;
        this.subscription.id = newSub.id;
        this.subscription.text = newSub.text;
        this.subscription.title = newSub.title;
    };
    Instrument.prototype.setVolume = function (val) {
        console.log('base setting volume on ' + this.name, val);
        this.volume = val;
    };
    // --------------------
    // Toggle on/off (mute)
    // --------------------
    Instrument.prototype.toggle = function (val) {
        this.isOn = val;
    };
    Instrument.prototype.on = function () { this.isOn = true; };
    Instrument.prototype.off = function () { this.isOn = false; };
    Instrument.prototype.cleanup = function () {
        this.cleanupImpl();
    };
    Instrument.prototype.dispose = function () {
        this.disposeDelay();
        this.disposeImpl();
    };
    Instrument.prototype.disposeDelay = function () {
        if (this.effectDelay) {
            this.effectDelay.dispose();
            this.effectDelay = null;
        }
    };
    Instrument.prototype.disposeReverb = function () {
        if (this.effectReverb) {
            this.effectReverb.dispose();
            this.effectReverb = null;
        }
    };
    return Instrument;
}());
export { Instrument };
