<template>

  <div class="p-12">

    <h1 class="my-2 text-xl font-black">Tone.start()</h1>
    <button onclick="Tone.start()" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Tone.start()</button>

    <h1 class="my-2 text-xl font-black">No Effects</h1>
    <button v-on:click="triggerPlain()" type="button" class="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Trigger</button>

    <h1 class="my-2 text-xl font-black">Reverb</h1>
    <button v-on:click="triggerReverb()" type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Trigger</button>

  </div>

</template>


<script>
import * as Pixelsong from "@/player/index.js";
import * as Tone from 'tone';


const synth = new Tone.Synth({
  oscillator: {
    type: "sine",
  },
  envelope: {
    attack: 0.01,
    decay: 0.8,
    sustain: 0.5,
    release: 1,
  }
});
synth.volume.value = -10;
synth.toDestination();

const synth2 = new Tone.Synth({
  oscillator: {
    type: "sine",
  },
  envelope: {
    attack: 0.01,
    decay: 0.8,
    sustain: 0.5,
    release: 1,
  }
});
synth2.volume.value = -10;

const reverb = new Tone.JCReverb();
reverb.set({roomSize:0.8, wet:0.3});

const nodes = [];
nodes.push(reverb)
nodes.push(Tone.Master);
synth2.chain(...nodes);



export default {
    name: "Test.vue",

    data() {
        return {
            websiteId: '7777-7777-777777',
            myId: Math.floor(Math.random() * 1000000),
        }
    },

    methods: {
      triggerPlain() {
        synth.triggerAttackRelease('C5', '8n')
      },

      triggerReverb() {
        synth2.triggerAttackRelease('C5', '8n')
      },
    },

    mounted() {

    }
}
</script>

<style scoped>

</style>
