<template>

    <div class="flex-1 bg-gray-50 dark:bg-gray-800">



        <div class="relative py-16 overflow-hidden">



            <!-- patterns -->
            <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg class="absolute top-12 left-full transform translate-x-16" width="604" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200 dark:text-gray-900" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="604" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="604" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200 dark:text-gray-900" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg class="absolute bottom-12 left-full transform translate-x-32" width="604" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200 dark:text-gray-900" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>



            <!-- help content -->
            <div class="relative px-4 sm:px-6 lg:px-8">


                <div class="text-lg max-w-prose mx-auto">
                    <h1>
                        <span class="block text-base text-center text-light-blue-600 font-semibold tracking-wide uppercase">Quick Start</span>
                        <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight sm:text-4xl">How to get started</span>
                    </h1>
                    <p class="mt-8 text-xl text-gray-500 dark:text-gray-300 leading-8">To get started, copy the following code onto your webpages. This will record pageload events for any pages containing the script.</p>
                </div>


                <div class="mt-6 prose prose-indigo prose-lg text-gray-500 dark:text-gray-300 mx-auto">
                    <p v-if="loggedIn==false" class="bg-indigo-500 text-white p-4">
                        <span class="font-bold">Note</span> - Login first to personalize this script for your account.
                    </p>
                    <pre class="dark:bg-gray-700">{{ scriptMain }}</pre>
                    <p>You can also bind other events to your stream. Like clicking a button, or clicking all of the things.</p>
                    <pre class="dark:bg-gray-700">{{ scriptClicks }}</pre>


                    <p>You can easily install Pixelsong using <span class="font-bold">Google Tag Manager</span>.  If you are already
                        using it, just add a custom HTML tag by following these instructions.
                        <a target="_blank" class="" style="color:#0ea5e9;" href="https://support.google.com/tagmanager/answer/6107167">(support.google.com)</a>
                    </p>
                </div>


                <div class="text-lg max-w-prose mx-auto mt-48">
                    <h1>
                        <span class="block text-base text-center text-light-blue-600 font-semibold tracking-wide uppercase">Documentation</span>
                        <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight sm:text-4xl">Making Sounds</span>
                    </h1>
                    <p class="mt-8 text-xl text-gray-500 dark:text-gray-300 leading-8">To hear something, start by connecting instruments and effects to events that are generated by your users.</p>
                </div>

                <div class="mt-6 prose prose-indigo prose-lg text-gray-500 dark:text-gray-300 mx-auto">

                    <h2 class="dark:text-white">Events</h2>
                    <div class="grid grid-cols-2 gap-4 -mr-32">
                        <div class="col-span-1">
                            <p>You won't hear anything until events from your website traffic are streaming into Pixelsong.
                                You can confirm that events are streaming by creating or loading a song and watching the events sidebar on the left (desktop).
                                The panel will chart their occurrences & rates in real-time.
                            </p>
                            <p>Instruments are mapped to these events using simple pattern matching.</p>
                        </div>
                        <div class="">
                            <img class="shadow-xl rounded-xl" src="/images/screenshot2.png" alt="" style="margin-top:0px;">
                        </div>
                    </div>


                    <h2 class="dark:text-white">Songs</h2>
                    <p>Songs are the main body of work within Pixelsong.
                        Within a song, you choose a musical key, and add instruments which are mapped to different events feeding in from your website.
                        As long as your website is generating events, the instruments will trigger.
                    </p>
                    <p class="text-red-500">Your song is never auto-saved!  You must click the save button if you want to keep changes.</p>



                    <h2 class="dark:text-white">Instruments</h2>
                    <p>There are three steps to adding a new instrument to your existing song.</p>
                    <ul>
                        <li>Add an instrument to the song</li>
                        <li>Pick an event to start listening to.</li>
                        <li>Click Accept to add it to your song.</li>
                    </ul>
                    <p class="bg-indigo-500 text-white p-4">
                        <span class="font-bold">Note</span> - This version of Pixelsong supports simple <span class="italic font-bold">windchime</span> instruments that trigger & release a single note or chord each time an event is received.
                        Expect more instruments with more flexibility in the future!
                    </p>



                    <h3 class="dark:text-white">Listening To</h3>
                    <p>This is the event this instrument is bound to.  (eg: a user clicks the <span class="font-bold">complete-purchase</span> button.)</p>
                    <div class="grid grid-cols-2 rounded-xl text-white text-center gap-1">
                        <div class="col-span-2 bg-blue-500 rounded-xl">Pageloads</div>
                        <div class="bg-blue-400 rounded-xl p-2">
                            <div class="font-bold">Path</div>
                            <div class="text-sm">The portion of the browser's location that is after the domain name.</div>
                            <div class="text-sm text-gray-900">/about</div>
                        </div>
                        <div class="bg-blue-400 rounded-xl p-2">
                            <div class="font-bold">Page Title</div>
                            <div class="text-sm">The title you typically see on tabs within the browser.</div>
                            <div class="text-sm text-gray-900">About us | Pixelsong</div>
                        </div>
                    </div>

                    <div class="mt-10 grid grid-cols-3 rounded-xl text-white text-center gap-1">
                        <div class="col-span-3 bg-blue-500 rounded-xl">Clicks</div>
                        <div class="bg-blue-400 rounded-xl p-2">
                            <div class="font-bold">Text</div>
                            <div class="text-sm">The button or link text that was clicked.</div>
                            <div class="text-sm text-gray-900">Click here to checkout</div>
                        </div>
                        <div class="bg-blue-400 rounded-xl p-2">
                            <div class="font-bold">ID</div>
                            <div class="text-sm">The ID of the html element that was clicked.</div>
                            <div class="text-sm text-gray-900">button-1</div>
                        </div>
                        <div class="bg-blue-400 rounded-xl p-2">
                            <div class="font-bold">Tag</div>
                            <div class="text-sm">The HTML tagname for the element that was clicked</div>
                            <div class="text-sm text-gray-900">&lt;button&gt;</div>
                        </div>
                    </div>


                    <p>
                        When you map to a <span class="font-bold">path</span> or <span class="font-bold">page title</span>, you can use asterisks (*) multiple times within the pattern for wildcard matching.  For instance:
                    </p>

                    <!-- pattern matching example 1 -->
                    <div class="grid grid-cols-2 border rounded-xl p-4">
                        <div class="col-span-2 font-bold">/dashboard/*</div>
                        <div>/dashboard/action</div>
                        <div class="flex items-center text-green-500">
                            <svg class="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            Matches!
                        </div>

                        <div>/dashboard/action/delete</div>
                        <div class="flex items-center text-green-500">
                            <svg class="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            Matches!
                        </div>

                        <div>/dashboard</div>
                        <div class="flex items-center text-red-500">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                            Does not match
                        </div>
                    </div>

                    <!-- pattern matching example 2 -->
                    <div class="grid grid-cols-2 border rounded-xl p-4 mt-10">
                        <div class="col-span-2 font-bold">/dashboard/*/delete</div>
                        <div>/dashboard/action/delete</div>
                        <div class="flex items-center text-green-500">
                            <svg class="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            Matches!
                        </div>

                        <div>/dashboard/commit/delete</div>
                        <div class="flex items-center text-green-500">
                            <svg class="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                            Matches!
                        </div>

                        <div>/dashboard/delete</div>
                        <div class="flex items-center text-red-500">
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                            Does not match
                        </div>
                    </div>


                    <h3 class="dark:text-white">Notes/Chords</h3>
                    <p>Instruments can be configured to play semi-random notes or chords, depending on your preference.
                        The notes are picked from the key/type you have selected for your song, and played for a very short duration.
                    </p>
                    <p>You choose an octave range that you would like to draw from as well, and chords are constructed from random octave ranges for each note in the chord.</p>
                    <div class="font-bold">Octave Range Example</div>
                    <span class="relative mt-2 flex shadow-sm rounded-md justify-between">
                        <button type="button" class="flex-1 relative items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium bg-transparent">2</button>
                        <button type="button" class="flex-1 -ml-px relative items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:outline-none bg-transparent">3</button>
                        <button type="button" class="flex-1 -ml-px relative items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:outline-none bg-light-blue-500 text-white">4</button>
                        <button type="button" class="flex-1 -ml-px relative items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:outline-none bg-light-blue-500 text-white">5</button>
                        <button type="button" class="flex-1 -ml-px relative items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:outline-none bg-light-blue-500 text-white">6</button>
                        <button type="button" class="flex-1 -ml-px relative items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium bg-transparent">7</button>
                    </span>
                    <div class="mt-2 text-sm">This example will choose notes within the 4th, 5th, and 6th octaves.</div>



                    <h3 class="dark:text-white">Oscillator</h3>
                    <p>Pixelsong sounds begin with an oscillator, which produces a waveform of a few different types.
                        Those familiar with synthesizers will already be familiar with these types.</p>

                    <div class="grid grid-cols-2 sm:grid-cols-5">

                        <div class="flex flex-col p-2 items-center text-center">
                            <svg class="w-6 h-6 text-green-500" x="0px" y="0px" fill="currentColor" viewBox="0 0 60 60" xml:space="preserve"><path d="M18.9,42.6c-4.8,0-9.9-4-15.1-11.8c-0.5-0.7-0.3-1.6,0.4-2.1c0.7-0.5,1.6-0.3,2.1,0.4c4.9,7.3,9.5,10.9,13.4,10.4 c5.7-0.8,8.9-9.9,8.9-10c0.1-0.4,3.8-11,11.4-12.1c5.2-0.7,10.7,3.2,16.3,11.7c0.5,0.7,0.3,1.6-0.4,2.1c-0.7,0.5-1.6,0.3-2.1-0.4 c-4.9-7.3-9.5-10.9-13.4-10.4c-5.7,0.8-8.9,9.9-8.9,10c-0.1,0.4-3.8,11-11.4,12.1C19.7,42.6,19.3,42.6,18.9,42.6z"/></svg>
                            <span class="font-bold">Sine</span>
                            <span class="">A soft tone of gentle rising & falling.</span>
                        </div>

                        <div class="flex flex-col p-2 items-center text-center">
                            <svg class="w-6 h-6 text-green-500" x="0px" y="0px" fill="currentColor" viewBox="0 0 60 60" xml:space="preserve"><path d="M29,42.6H11c-1.4,0-2.5-1.1-2.5-2.5v-8.6H5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h4c1.4,0,2.5,1.1,2.5,2.5v8.6h17V19.9 c0-1.4,1.1-2.5,2.5-2.5h17.6c1.4,0,2.5,1.1,2.5,2.5v8.6H55c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5h-4.4c-1.4,0-2.5-1.1-2.5-2.5 v-8.6H31.5v19.7C31.5,41.5,30.4,42.6,29,42.6z M9,31.5L9,31.5L9,31.5z"/></svg>
                            <span class="font-bold">Square</span>
                            <span class="">A lot of early video games use this.</span>
                        </div>

                        <div class="flex flex-col p-2 items-center text-center">
                            <svg class="w-6 h-6 text-green-500" x="0px" y="0px" fill="currentColor" viewBox="0 0 60 60" xml:space="preserve"><path d="M25,49.5c-0.9,0-1.8-0.5-2.2-1.3L22.7,48l-7.8-21.1l-4.9,6.4H5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h3.4l4.5-5.8 c0.5-0.7,1.3-1.2,2.2-1.1c0.9,0,1.7,0.5,2.2,1.3l0.1,0.2l7.6,20.4l7.8-33.1c0.3-1,1.2-1.7,2.3-1.7c1.1,0,2,0.6,2.4,1.6L45,36 l3.9-12.7H55c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5h-3.9l-3.9,12.8l-0.2,0.2c-0.5,0.7-1.4,1.1-2.3,1c-0.9-0.1-1.7-0.7-2-1.6 l-7.7-23.9l-7.7,32.9c-0.3,1-1.2,1.6-2.2,1.7C25.1,49.5,25,49.5,25,49.5z"/></svg>
                            <span class="font-bold">Triangle</span>
                            <span class="">In between a sine wave and a sawtooth.</span>
                        </div>

                        <div class="flex flex-col p-2 items-center text-center">
                            <svg class="w-6 h-6 text-green-500" x="0px" y="0px" fill="currentColor" viewBox="0 0 60 60" xml:space="preserve"><path d="M29,48.3c-0.8,0-1.5-0.4-2-1L11.5,25.6v3.1c0,1.4-1.1,2.5-2.5,2.5H5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h3.5v-4.1 c0-1.1,0.7-2,1.7-2.4c1-0.3,2.2,0,2.8,0.9l15.4,21.6l0-30c0-1.1,0.7-2.1,1.9-2.4c1.1-0.3,2.2,0.1,2.8,1.1l15.8,20.8v-3 c0-1.4,1.1-2.5,2.5-2.5H55c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5h-3.1v4c0,1.1-0.7,2.1-1.9,2.4c-1.1,0.3-2.2-0.1-2.8-1.1 L31.5,15.7l0,30c0,1.1-0.7,2-1.7,2.4C29.5,48.2,29.2,48.3,29,48.3z"/></svg>
                            <span class="font-bold">Sawtooth</span>
                            <span class="">A sharper sound with more grit.</span>
                        </div>

                        <div class="flex flex-col p-2 items-center text-center">
                            <svg class="w-6 h-6 text-green-500" x="0px" y="0px" fill="currentColor" viewBox="0 0 60 60" xml:space="preserve"><path d="M29,49.3c-1.1,0-2.2-0.5-2.8-1.5L12.5,28.7c0,1.9-1.6,3.4-3.5,3.4H5c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h2.5v-3.1 c0-1.5,1-2.9,2.4-3.3c1.5-0.5,3,0.1,3.9,1.3l13.6,19.1l0-26.9c0-1.6,1-3,2.6-3.4c1.5-0.4,3,0.2,3.8,1.5l14,18.4v-0.1 c0-1.9,1.6-3.5,3.5-3.5H55c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5h-2.1v3c0,1.6-1,3-2.6,3.4c-1.5,0.4-3-0.2-3.8-1.5l-14-18.4 l0,27.1c0,1.5-1,2.9-2.4,3.3C29.7,49.2,29.3,49.3,29,49.3z"/></svg>
                            <span class="font-bold">Fat Sawtooth</span>
                            <span class="">Think <a style="color:#0ea5e9;" target="_blank" href="https://tonejs.github.io/examples/jump">Jump by Van Halen</a></span>
                        </div>

                    </div>



                    <h3 class="dark:text-white">Envelope</h3>
                    <p>Envelopes shape the sound produced by an oscillator.  Without an envelope, the sound is raw and unchanging, like a very simple robot screaming forever.</p>
                    <p>By controlling the volume of the sound through 4 stages of it's lifecycle, we get variation. </p>
                    <p>Pixelsong's envelope generator has four stages: attack, decay, sustain, and release (ADSR).</p>
                    <ul>
                        <li><span class="font-bold">Attack</span> is the time taken for initial run-up of level from nil to peak, beginning when the key is pressed.</li>
                        <li><span class="font-bold">Decay</span> is the time taken for the subsequent run down from the attack level to the designated sustain level.</li>
                        <li><span class="font-bold">Sustain</span> is the level during the main sequence of the sound's duration, until the key is released.</li>
                        <li><span class="font-bold">Release</span> is the time taken for the level to decay from the sustain level to zero after the key is released.</li>
                    </ul>
                    <p class="bg-indigo-500 text-white p-4">
                        Info snipped from Wikipedia.  Read more here - <a style="color:white;" href="https://en.wikipedia.org/wiki/Envelope_(music)">https://en.wikipedia.org/wiki/Envelope_(music)</a>
                    </p>


                    <h3 class="dark:text-white">Effects</h3>
                    <p>Pixelsound currently has three effects per instrument, and they are chained in the following order:</p>

                    <div class="flex items-center space-x-4">
                        <span class="">Distortion</span>
                        <svg class="text-yellow-500 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                        <span class="">Delay</span>
                        <svg class="text-yellow-500 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                        <span class="">Reverb</span>
                    </div>
                    <p>
                        <span class="font-bold">Wetness</span> is a value between [0 - 1] that specifies how much of an effect is included in the output mix.
                    </p>
                    <table class="">
                        <tr><td class="text-red-500">0</td><td>Effectively bypasses the effect</td></tr>
                        <tr><td>.5</td><td>A balanced output of the original sound with the processed sound</td></tr>
                        <tr><td class="text-green-500">1</td><td>None of the original sound is included in the output mix.</td></tr>
                    </table>

                </div>
            </div>
        </div>


        <!-- mailing list -->
        <div class="mt-24">
            <div class="relative sm:py-16">
                <div aria-hidden="true" class="hidden sm:block">
                    <svg class="absolute top-8 left-1/2 -ml-3" width="404" height="392" fill="none" viewBox="0 0 404 392">
                        <defs>
                            <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200 dark:text-gray-900" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
                    </svg>
                </div>
                <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                    <div class="relative rounded-2xl px-6 py-10 bg-light-blue-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                        <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                            <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                                <path class="text-light-blue-500 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />
                                <path class="text-light-blue-700 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />
                            </svg>
                        </div>
                        <div class="relative">
                            <div class="sm:text-center">
                                <h2 class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                                    Get updates as the product evolves
                                </h2>
                                <p class="mt-6 mx-auto max-w-2xl text-lg text-light-blue-200">
                                    This product is constantly evolving, so sign up here if you'd like to stay in the loop as major progress is made.
                                </p>
                            </div>

                            <!-- Begin Mailchimp Signup Form -->
                            <div id="mc_embed_signup">
                                <form action="https://gmail.us1.list-manage.com/subscribe/post?u=60362569a7b76d8ac9179a351&amp;id=b2aef17639" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                    <div id="mc_embed_signup_scroll" class="mt-12 sm:mx-auto sm:max-w-lg sm:flex">

                                        <div class="mc-field-group min-w-0 flex-1">
                                            <label for="mce-EMAIL" class="sr-only">Email Address </label>
                                            <input type="email" value="" name="EMAIL" class="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-light-blue-600" placeholder="Enter your email" id="mce-EMAIL">
                                        </div>
                                        <div id="mce-responses" class="clear mt-4 sm:mt-0 sm:ml-3">
                                            <div class="response" id="mce-error-response" style="display:none"></div>
                                            <div class="response" id="mce-success-response" style="display:none"></div>
                                        </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_60362569a7b76d8ac9179a351_b2aef17639" tabindex="-1" value=""></div>
                                        <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="duration-300 block w-full rounded-md border border-transparent px-5 py-3 bg-light-blue-500 text-base font-medium text-white shadow hover:bg-light-blue-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-light-blue-600 sm:px-10"></div>
                                    </div>
                                </form>
                            </div>
                            <!--End mc_embed_signup-->

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</template>

<script>

export default {
    name: "Docs.vue",
    data() {
        return {
            websiteId: '',
            scriptMain: '',
            scriptClicks: '',
            showDiscordWidget: false,
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.user;
        },

        loggedIn() {
            return this.currentUser != null;
        },
    },

    mounted() {
        if (!this.currentUser) {
            this.$store.dispatch('relogin')
                .then(() => {this.initialize();})
                .catch((err) =>this.initialize());
        } else {this.initialize();}
    },

    methods: {

        initialize() {

            if(this.currentUser) {
                this.websiteId = this.$store.state.account.websiteId;
            }
            else {
                this.websiteId = '{{YOUR-PIXELSONG-ID-GOES-HERE}}';
            }

            this.setupScripts();
        },


        setupScripts() {
        {
            /* eslint-disable no-useless-escape */
            this.scriptMain =
`<script src="https://pixelsong.io/scripts/pixelsong.js"><\/script>
<script>
    window.addEventListener('load', function() {
        pixelsong('config', '${this.websiteId}');
        pixelsong('pageload');
    });
<\/script>`
            }

            this.scriptClicks =
`document.addEventListener( 'mousedown', (e) => {
    pixelsongEvent(e);  // logging all clicks
});
`
        },
    },
}
</script>

<style scoped>

</style>

