<script>
import Dashboard from './Dashboard.vue'
import * as Tone from "tone";

export default {
    name: "Demo",
    extends: Dashboard,
    data() {
        return {
            websiteId: 'e37be49b-7873-4d8b-9b95-4ebbf24c07cd',
            songId: '60425221b878f4e4aba01eac',
            isDemo : true,
        }
    },

    mounted() {
    },

    methods: {
        //save() {this.readonlyError();},
        //saveKey(){this.readonlyError();},
        //commitEdit(closeOnSave) {this.readonlyError();},
        saveToDatabase(config,closeOnSave) {
            this.saving = false;
            this.editing = false;
            this.readonlyError();
        },

        readonlyError() {
            this.$store.dispatch('error',{title:'Cannot save this file',subtitle:'This demonstration is read-only'});
        },
    }
}
</script>

<style scoped>

</style>
