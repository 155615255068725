import { Subscription } from "./data";
var PsConfig = /** @class */ (function () {
    function PsConfig(name) {
        this.version = '1.0';
        this.name = name;
        this.key = 'C';
        this.keyType = KeyType.Major;
        this.volume = 0.0;
        this.instruments = [];
    }
    return PsConfig;
}());
export { PsConfig };
var InstrumentConfig = /** @class */ (function () {
    function InstrumentConfig() {
        this.id = '';
        this.name = '';
        this.type = '';
        this.listenTo = '';
        this.subscription = new Subscription('', '', '', '', '', '');
        this.isOn = true;
        this.volume = -10;
        this.distortion = new DistortionConfig();
        this.delay = new DelayConfig();
        this.reverb = new ReverbConfig();
    }
    return InstrumentConfig;
}());
export { InstrumentConfig };
var WindchimeConfig = /** @class */ (function () {
    function WindchimeConfig() {
        this.notegen = new NoteGeneratorConfig();
        this.duration = "";
    }
    return WindchimeConfig;
}());
export { WindchimeConfig };
var DistortionConfig = /** @class */ (function () {
    function DistortionConfig() {
        this.wet = 0.0;
        this.distortion = .5;
    }
    return DistortionConfig;
}());
export { DistortionConfig };
var DelayConfig = /** @class */ (function () {
    function DelayConfig() {
        this.wet = 1.0;
        this.time = '8n';
        this.feedback = 0.0;
    }
    return DelayConfig;
}());
export { DelayConfig };
var ReverbConfig = /** @class */ (function () {
    function ReverbConfig() {
        this.wet = 1;
        this.roomSize = 1;
    }
    return ReverbConfig;
}());
export { ReverbConfig };
export var SpecificNoteGenMode;
(function (SpecificNoteGenMode) {
    SpecificNoteGenMode["Off"] = "off";
    SpecificNoteGenMode["Sequential"] = "sequential";
    SpecificNoteGenMode["Random"] = "random";
    SpecificNoteGenMode["Combine"] = "combine";
})(SpecificNoteGenMode || (SpecificNoteGenMode = {}));
export var KeyType;
(function (KeyType) {
    KeyType["Major"] = "Major";
    KeyType["Minor_Natural"] = "Minor (natural)";
    KeyType["Minor_Harmonic"] = "Minor (harmonic)";
    KeyType["Minor_Melodic"] = "Minor (melodic)";
})(KeyType || (KeyType = {}));
export var NoteType;
(function (NoteType) {
    NoteType["Note"] = "note";
    NoteType["Chord"] = "chord";
})(NoteType || (NoteType = {}));
export var KeySource;
(function (KeySource) {
    KeySource["Song"] = "song";
    KeySource["Override"] = "override";
})(KeySource || (KeySource = {}));
var NoteGeneratorConfig = /** @class */ (function () {
    function NoteGeneratorConfig() {
        /* Specific Notes/Chords */
        this.specific = SpecificNoteGenMode.Off;
        /* Theory based properties */
        this.keySource = KeySource.Song;
        this.type = NoteType.Note;
        this.key = "C";
        this.keyType = KeyType.Major;
        this.range = [3];
    }
    return NoteGeneratorConfig;
}());
export { NoteGeneratorConfig };
