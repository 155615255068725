<template>

    <div class="flex-1 bg-light-blue-500 dark:bg-gray-700">
        <div class="flex flex-col justify-center sm:py-12 bg-light-blue-500 dark:bg-gray-700 m-auto">
            <div class="relative py-3 sm:max-w-xl sm:mx-auto">
                <div class="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 rounded-3xl hidden md:block"></div>
                <div class="relative px-4 py-10 bg-white dark:bg-gray-800 dark:text-white shadow-lg rounded-3xl sm:p-20 m-4 md:m-0">
                    <div class="mx-auto">
                        <div class="text-center">
                            <img alt="Pixelsong logo" class="logo m-auto h-20" src="/images/logo.svg"/>
                            <h1 class="text-4xl mt-4 text-gray-700 dark:text-white">Pixelsong</h1>
                        </div>
                        <div class="divide-y divide-gray-200">
                            <div class="py-8 text-base leading-6 space-y-4 text-gray-700 dark:text-white sm:text-lg sm:leading-7">
                                <p>
                                    Turn your web traffic into generative music that can be <strong class="">informative</strong>,
                                    <span class="bouncytext">
                                      <span>i</span>
                                      <span>n</span>
                                      <span>t</span>
                                      <span>e</span>
                                      <span>r</span>
                                      <span>e</span>
                                      <span>s</span>
                                      <span>t</span>
                                      <span>i</span>
                                      <span>n</span>
                                      <span>g</span>
                                  </span>
                                    , or neither.
                                </p>
                                <ul class="list-disc space-y-2">
                                    <li class="flex items-start">
                                      <span class="h-6 flex items-center sm:h-7">
                                          <svg class="flex-shrink-0 h-5 w-5 text-cyan-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                      </span>
                                        <div class="ml-2">
                                            Include <code class="text-sm font-bold text-gray-900 dark:text-white underline">pixelsong.js</code> on your website.
                                        </div>
                                    </li>
                                    <li class="flex items-start">
                                      <span class="h-6 flex items-center sm:h-7">
                                        <svg class="flex-shrink-0 h-5 w-5 text-cyan-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd"></path></svg>
                                      </span>
                                        <div class="ml-2">
                                            Automatically send us page loads, or other user generated events.
                                        </div>
                                    </li>
                                    <li class="flex items-start">
                                      <span class="h-6 flex items-center sm:h-7">
                                        <svg class="flex-shrink-0 h-5 w-5 text-cyan-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z"></path></svg>
                                      </span>
                                        <div class="ml-2">Feed events into synths, effects, & more to hear your website for the first time.</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                                <div>Ready to hear the madness?</div>
                                <div>
                                    <router-link to="/about" class="text-cyan-600 hover:text-cyan-700">Learn More &rarr;</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <footer id="footer" class="text-center bg-gray-50 dark:bg-gray-800 text-black dark:text-white p-2">
        Copyright 2023 Pixelsong
    </footer>

</template>

<script>
export default {
    name: "Home.vue",

    computed: {
        currentUser() {
            return this.$store.state.user;
        },

        loggedIn() {
            return this.currentUser != null;
        },
    },

    mounted() {
        if (!this.currentUser) {
            this.$store.dispatch('relogin')
                .then(() => {
                    this.initialize();
                })
                .catch((err) => this.initialize());
        } else {
            this.initialize();
        }
    },

    beforeUnmount() {
        let footer = document.getElementById("footer")
        footer.classList.remove("bg-light-blue-500", "dark:bg-gray-700")
        footer.classList.remove("text-white");
        footer.classList.add("bg-gray-50", "dark:bg-gray-800");
        footer.classList.add("text-black");
    },

    methods: {
        initialize() {
            let footer = document.getElementById("footer")
            footer.classList.remove("bg-gray-50", "dark:bg-gray-800")
            footer.classList.remove("text-black")
            footer.classList.add("bg-light-blue-500", "dark:bg-gray-700");
            footer.classList.add("text-white");
        }
    }
}
</script>

<style scoped>
.bouncytext span {
    position: relative;
    top: 1px;
    animation: bounce 0.2s ease infinite alternate;
}

.bouncytext span:nth-child(2) {
    animation-delay: 0.1s;
}

.bouncytext span:nth-child(3) {
    animation-delay: 0.2s;
}

.bouncytext span:nth-child(4) {
    animation-delay: 0.3s;
}

.bouncytext span:nth-child(5) {
    animation-delay: 0.4s;
}

.bouncytext span:nth-child(6) {
    animation-delay: 0.5s;
}

.bouncytext span:nth-child(7) {
    animation-delay: 0.6s;
}

.bouncytext span:nth-child(8) {
    animation-delay: 0.2s;
}

.bouncytext span:nth-child(9) {
    animation-delay: 0.3s;
}

.bouncytext span:nth-child(10) {
    animation-delay: 0.4s;
}

.bouncytext span:nth-child(11) {
    animation-delay: 0.5s;
}

.bouncytext span:nth-child(12) {
    animation-delay: 0.6s;
}

.bouncytext span:nth-child(13) {
    animation-delay: 0.7s;
}

.bouncytext span:nth-child(14) {
    animation-delay: 0.8s;
}

/* ANIMATION */
@-webkit-keyframes bounce {
    100% {
        top: -1px;
    }
}
</style>
