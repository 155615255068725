<template>

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>


    <div class="flex flex-col h-screen">

        <NavBar class="bg-light-blue-500 dark:bg-gray-700"></NavBar>
        <router-view class="text-black dark:text-white"></router-view>

    </div>


    <!-- notification bar -->
    <div class="fixed z-20 inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
        <!--
          Notification panel, show/hide based on alert state.
          Entering: "transform ease-out duration-300 transition"
            From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            To: "translate-y-0 opacity-100 sm:translate-x-0"
          Leaving: "transition ease-in duration-100"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <transition
                name="error-transition"
                enter-active-class="transform ease-out duration-500 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
            <div v-if="$store.state.errors.length>0" class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">

            <div class="p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0">
                        <!-- Heroicon name: outline/check-circle -->
                        <svg class="animate__animated animate__infinite animate__pulse w-6 h-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    </div>
                    <div class="flex flex-col space-y-4 ml-3 w-0 flex-1 pt-0.5">
                        <div v-for="error in $store.state.errors" :key="error">
                            <p class="text-sm font-medium text-red-500">
                                {{ error.title }}
                            </p>
                            <p class="mt-1 text-sm text-gray-500">
                                {{ error.subtitle }}
                            </p>
                        </div>
                    </div>
                    <div class="ml-4 flex-shrink-0 flex">
                        <button @click="clearErrors()" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span class="sr-only">Close</span>
                            <!-- Heroicon name: solid/x -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>


        </div>
        </transition>
    </div>



</template>


<script>
import NavBar from "@/components/NavBar";

export default {
    name: 'App',
    inject: ['emitter'],
    data() {
        return {

        }
    },
    components: {
        NavBar
    },
    mounted() {

        window.onerror = function (msg, url, line, col, error) {
            console.error("ERROR: " + msg);
            console.error("  Url  : " + url);
            console.error("  Line : " + line);
            console.error("  Col  : " + col);
            console.error("  Error: " + error);
        }
    },

    unmounted() {
    },

    methods: {
        clearErrors() {
            this.$store.dispatch('errorClear');
        }
    },
}

</script>


<style>
html {

}

#app {
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
