import * as Tone from "tone";
var EnvelopeConfig = /** @class */ (function () {
    function EnvelopeConfig() {
        this.attack = 0.0;
        this.decay = 0.0;
        this.sustain = 0.0;
        this.release = 0.0;
    }
    return EnvelopeConfig;
}());
export { EnvelopeConfig };
var Envelope = /** @class */ (function () {
    function Envelope(config, emitter) {
        this.envelope = new Tone.Envelope(config);
        this.emitter = emitter;
        console.log('envelope created', this.envelope);
    }
    Envelope.prototype.get = function () {
        return this.envelope;
    };
    return Envelope;
}());
export { Envelope };
