<template>

    <div class="">

        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="py-16 bg-gray-50 dark:bg-gray-800 overflow-hidden lg:py-24">
            <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl" >


                <!-- pattern -->
                <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
                    <defs>
                        <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <circle r="2" cx="2" cy="2" class="text-gray-200 dark:text-gray-900" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
                </svg>


                <!-- title/subtitle -->
                <div class="relative text-center">
                    <h2 class="text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl">
                        What is Pixelsong.io?
                    </h2>
                    <p class="mt-4 max-w-3xl mx-auto text-xl text-gray-500 dark:text-gray-200">
                        Turn your web traffic into generative music that can be <strong class="">informative</strong>,
                        <span class="bouncytext">
                                      <span>i</span>
                                      <span>n</span>
                                      <span>t</span>
                                      <span>e</span>
                                      <span>r</span>
                                      <span>e</span>
                                      <span>s</span>
                                      <span>t</span>
                                      <span>i</span>
                                      <span>n</span>
                                      <span>g</span>
                                  </span>
                        , or neither.
                    </p>

                    <router-link class="duration-300 inline-block px-6 py-4 mt-12 rounded-full border border-light-blue-500 text-light-blue-500 hover:bg-light-blue-500 hover:text-white" to="/signup">Get Started</router-link>

                </div>


                <!-- first section -->
                <div class="relative mt-12 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                    <div class="relative">
                        <h3 class="text-2xl font-extrabold tracking-tight sm:text-3xl">
                            The story so far
                        </h3>
                        <p class="mt-3 text-lg text-gray-500 dark:text-gray-300">
                            Earlier in life, when I created <a target="_blank" class="text-light-blue-500 underline" href="https://www.prekindle.com">an online ticketing platform</a>,
                            I used a log-scraper to
                            <a @click="coin()" class="inline-flex items-center cursor-pointer text-light-blue-500 underline">
                                <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                play a chime
                            </a>
                            everytime a successful purchase was made. It was exciting to hear the chime,
                            and notice changes as sales ebbed and flowed. I always wanted to make it sound more interesting,
                            so I built <strong>Pixelsong</strong> for myself and others.
                        </p>

                        <dl class="mt-10 space-y-10">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-light-blue-500 text-white">
                                        <!-- Heroicon name: outline/globe-alt -->
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path></svg>
                                    </div>
                                </div>
                                <div class="ml-4">
                                    <dt class="text-lg leading-6 font-medium">
                                        Generative music is cool
                                    </dt>
                                    <dd class="mt-2 text-base text-gray-500 dark:text-gray-300">
                                        I am inspired by the interesting outcomes of Brian Eno's tape loops.  Surely we can use our web traffic data to craft something equally beautiful.
                                        <a target="_blank" class="text-light-blue-500 underline cursor-pointer" v-on:click="showTapeLoopVideo=!showTapeLoopVideo">Watch a video</a> by Simon Hutchinson about tape loops.
                                    </dd>
                                </div>
                            </div>

                            <transition
                                name="youtube-transition-1"
                                enter-active-class="ease-in-out duration-500"
                                enter-from-class="opacity-0 -translate-y-40 scale-50"
                                enter-to-class="opacity-100 translate-y-0 scale-100"
                                leave-active-class="ease-in duration-200"
                                leave-from-class="opacity-100 translate-y-0 scale-100"
                                leave-to-class="opacity-0 -translate-y-40 scale-50">
                            <div v-if="showTapeLoopVideo" class="relative overflow-hidden transform transition-all" style="padding-top: 30px; padding-bottom:56.25%; height:0;">

                                <iframe v-if="showTapeLoopVideo" class="absolute top-0 left-0 w-full h-full" src="https://www.youtube.com/embed/7sTrn39TT7k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                            </div>
                            </transition>

                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-light-blue-500 text-white">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"></path></svg>
                                    </div>
                                </div>
                                <div class="ml-4">
                                    <dt class="text-lg leading-6 font-medium">
                                        Get to know your website
                                    </dt>
                                    <dd class="mt-2 text-base text-gray-500 dark:text-gray-300">
                                        Imagine getting familiar with the slow murmurs of your e-commerce site, then listening to Black-Friday, or throughout an email campaign.
                                    </dd>
                                </div>
                            </div>

                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-light-blue-500 text-white">
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path></svg>
                                    </div>
                                </div>
                                <div class="ml-4">
                                    <dt class="text-lg leading-6 font-medium">
                                        Monitoring via sound
                                    </dt>
                                    <dd class="mt-2 text-base text-gray-500 dark:text-gray-300">
                                        Can we find a way to make this sonic information useful for monitoring our website?  The answer is: <span class="italic">maybe</span>.
                                    </dd>
                                </div>
                            </div>

                        </dl>
                    </div>

                    <div class="mt-10 -mx-2 relative lg:mt-0" aria-hidden="true">
                        <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                            <defs>
                                <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                        </svg>
                        <img class="relative mx-auto shadow-xl rounded-xl" src="/images/screenshot1.png" alt="">
                        <img class="relative mx-auto shadow-xl rounded-xl -top-40 left-40" src="/images/screenshot2.png" alt="">
                    </div>
                </div>


                <!-- second pattern -->
                <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
                    <defs>
                        <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" class="text-gray-200 dark:text-gray-900" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                </svg>


                <!-- second section -->
                <div class="relative mt-12 sm:mt-16 lg:mt-24">
                    <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                        <div class="lg:col-start-2">
                            <h3 class="text-2xl font-extrabold tracking-tight sm:text-3xl">
                                Created by Pete Swulius
                            </h3>
                            <p class="mt-3 text-lg text-gray-500 dark:text-gray-300">
                                I've long been a fan of <a class="text-light-blue-500 underline" target="_blank" href="https://en.wikipedia.org/wiki/Generative_music">generative music</a>
                                and <a class="text-light-blue-500 underline" target="_blank" href="https://en.wikipedia.org/wiki/Distributed_computing">distributed systems</a>.
                                I love <a class="text-light-blue-500 underline" target="_blank" href="https://www.prekindle.com/">building computer systems</a> of all varieties,
                                and <a class="text-light-blue-500 underline" target="_blank" href="https://soundcloud.com/thevirgintrebles/spectrograph">creating music</a> when I can.
                            </p>

                            <dl class="mt-10 space-y-10">
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-light-blue-500 text-white">
                                            <!-- Heroicon name: outline/annotation -->
                                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="ml-4">
                                        <dt class="text-lg leading-6 font-medium">
                                            Get in touch
                                        </dt>
                                        <dd class="text-base text-gray-500 dark:text-gray-300 flex flex-wrap items-center space-x-2">
                                            <a class="text-light-blue-500 underline" href="https://github.com/pswulius">Github</a>
                                            <span>|</span>
                                            <a class="text-light-blue-500 underline" href="https://twitter.com/afuturepete">Twitter</a>
                                            <span>|</span>
                                            <a class="text-light-blue-500 underline" href="https://www.linkedin.com/in/pete-swulius-6a23a1ba/">LinkedIn</a>
                                            <span>|</span>
                                            <span class="">pswulius(at)gmail.com</span>
                                        </dd>
                                    </div>
                                </div>


                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-light-blue-500 text-white">
                                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"></path></svg>
                                        </div>
                                    </div>
                                    <div class="ml-4">
                                        <dt class="text-lg leading-6 font-medium">
                                            Thank you
                                        </dt>
                                        <dd class="text-base text-gray-500 dark:text-gray-300">
                                            Pixelsong is possible only through the tremendous effort of other people who have built the following tools, frameworks, and more.
                                            <div class="flex flex-wrap items-center mt-4 space-y-1 space-x-1">
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://tonejs.github.io/">Tone.js</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://github.com/tonaljs/tonal">Tonal</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://projectreactor.io/">Project Reactor</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://spring.io/reactive">Webflux</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://v3.vuejs.org/">Vue.js</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://tailwindcss.com/">Tailwind CSS</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://kafka.apache.org/">Kafka</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://www.mongodb.com/">MongoDB</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://nodejs.org/en/">Node.js</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://spring.io/">Spring</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://jwt.io/">JWT</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://swagger.io/">Swagger</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://kubernetes.io/">Kubernetes</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://www.docker.com/">Docker</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://helm.sh/">Helm</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://github.com/pswulius/pixelsong">Github</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://www.figma.com">Figma</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://www.mongodb.com/products/compass">Compass</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white"><a target="_blank" href="https://www.conduktor.io/">Conduktor</a></span>
                                                <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 hover:bg-light-blue-500 hover:text-white">more to come . . .</span>
                                            </div>
                                        </dd>
                                    </div>
                                </div>


                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                                            <svg class="w-6 h-6 animate__animated animate__heartBeat animate__infinite infinite" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg>
                                        </div>
                                    </div>
                                    <div class="ml-4">
                                        <dt class="text-lg leading-6 font-medium">
                                            Now listening to...
                                        </dt>
                                        <dd class="mt-2 text-base text-gray-500  dark:text-gray-300">
                                            During development of this app, I listened mostly to
                                            <a class="text-light-blue-500 underline" href="https://www.youtube.com/watch?v=6JeZR13dLLI">State Azure</a>,
                                            <a class="text-light-blue-500 underline" href="https://www.youtube.com/watch?v=r4htJvX4bP4">Robin Guthrie</a>, and
                                            <a class="text-light-blue-500 underline" href="https://www.youtube.com/channel/UCDDJRnc_LsRm-_CAfRcKCZA">Brian Eno</a>                                        </dd>
                                    </div>
                                </div>

                            </dl>
                        </div>

                        <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                            <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404" aria-hidden="true">
                                <defs>
                                    <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                            </svg>
                            <img class="relative mx-auto w-80 rounded-full shadow-xl" src="/images/photo.jpg" alt="">
                        </div>
                    </div>
                </div>


                <div class="text-center mt-32 lg:mt-12">
                    <router-link class="duration-300 inline-block px-6 py-4 mt-12 rounded-full border border-light-blue-500 text-light-blue-500 hover:bg-light-blue-500 hover:text-white" to="/signup">Get Started</router-link>
                </div>

                <!-- mailing list -->
                <div class="mt-12">
                    <div class="relative sm:py-16">
                        <div aria-hidden="true" class="hidden sm:block">
                            <svg class="absolute top-8 left-1/2 -ml-3" width="404" height="392" fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                        <rect x="0" y="0" width="4" height="4" class="text-gray-200 dark:text-gray-900" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
                            </svg>
                        </div>
                        <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
                            <div class="relative rounded-2xl px-6 py-10 bg-light-blue-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
                                <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                                    <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                                        <path class="text-light-blue-500 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />
                                        <path class="text-light-blue-700 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />
                                    </svg>
                                </div>
                                <div class="relative">
                                    <div class="sm:text-center">
                                        <h2 class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                                            Get updates as the product evolves
                                        </h2>
                                        <p class="mt-6 mx-auto max-w-2xl text-lg text-light-blue-200">
                                            This product is constantly evolving, so sign up here if you'd like to stay in the loop as major progress is made.
                                        </p>
                                    </div>

                                    <!-- Begin Mailchimp Signup Form -->
                                    <div id="mc_embed_signup">
                                        <form action="https://gmail.us1.list-manage.com/subscribe/post?u=60362569a7b76d8ac9179a351&amp;id=b2aef17639" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                            <div id="mc_embed_signup_scroll" class="mt-12 sm:mx-auto sm:max-w-lg sm:flex">

                                                <div class="mc-field-group min-w-0 flex-1">
                                                    <label for="mce-EMAIL" class="sr-only">Email Address </label>
                                                    <input type="email" value="" name="EMAIL" class="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-light-blue-600" placeholder="Enter your email" id="mce-EMAIL">
                                                </div>
                                                <div id="mce-responses" class="clear mt-4 sm:mt-0 sm:ml-3">
                                                    <div class="response" id="mce-error-response" style="display:none"></div>
                                                    <div class="response" id="mce-success-response" style="display:none"></div>
                                                </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_60362569a7b76d8ac9179a351_b2aef17639" tabindex="-1" value=""></div>
                                                <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="duration-300 block w-full rounded-md border border-transparent px-5 py-3 bg-light-blue-500 text-base font-medium text-white shadow hover:bg-light-blue-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-light-blue-600 sm:px-10"></div>
                                            </div>
                                        </form>
                                    </div>
                                    <!--End mc_embed_signup-->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>






    </div>


</template>

<script>
export default {
    name: "About.vue",
    data() {
      return  {
          showTapeLoopVideo : false,
      }
    },
    mounted() {
      if (!this.currentUser) {
        this.$store.dispatch('relogin')
            .then(() => {this.initialize();})
            .catch((err) =>this.initialize());
      } else {this.initialize();}
    },

    methods: {
        initialize() {

        },

        coin() {
            new Audio('/sounds/coin.mp3').play();
        },
    }
}
</script>

<style scoped>
.bouncytext span {
    position: relative;
    top: 1px;
    animation: bounce 0.2s ease infinite alternate;
}

.bouncytext span:nth-child(2) {
    animation-delay: 0.1s;
}

.bouncytext span:nth-child(3) {
    animation-delay: 0.2s;
}

.bouncytext span:nth-child(4) {
    animation-delay: 0.3s;
}

.bouncytext span:nth-child(5) {
    animation-delay: 0.4s;
}

.bouncytext span:nth-child(6) {
    animation-delay: 0.5s;
}

.bouncytext span:nth-child(7) {
    animation-delay: 0.6s;
}

.bouncytext span:nth-child(8) {
    animation-delay: 0.2s;
}

.bouncytext span:nth-child(9) {
    animation-delay: 0.3s;
}

.bouncytext span:nth-child(10) {
    animation-delay: 0.4s;
}

.bouncytext span:nth-child(11) {
    animation-delay: 0.5s;
}

.bouncytext span:nth-child(12) {
    animation-delay: 0.6s;
}

.bouncytext span:nth-child(13) {
    animation-delay: 0.7s;
}

.bouncytext span:nth-child(14) {
    animation-delay: 0.8s;
}

/* ANIMATION */
@-webkit-keyframes bounce {
    100% {
        top: -1px;
    }
}
</style>
