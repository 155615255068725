<template>

    <div class="flex flex-1 overflow-hidden">

        <!-- page -->
        <div class="flex relative flex-col flex-1
                   xl:overflow-hidden xl:flex-row">

            <!-- sidebar -->
            <SideBar v-if="!isMobile()" class="hidden xl:block" :psd2="psd2"></SideBar>


            <!-- content -->
            <div class="bg-gray-100 dark:bg-gray-800 p-4 sm:p-12 flex-1 overflow-auto">


                <!-- callout banner for demo -->
                <div v-if="isDemo" class="rounded-md bg-blue-100 p-4 mb-12">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <!-- Heroicon name: solid/information-circle -->
                            <svg class="h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"/>
                            </svg>
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between">
                            <p class="text-sm text-blue-700">
                                This demonstration is driven by simulated traffic.  You can make changes, but all changes will be lost when you leave the page.
                            </p>
                        </div>
                    </div>
                </div>


                <!-- song bar -->
                <div>
                    <!-- song upper line -->
                    <div class="flex items-center justify-between mb-4">

                        <!-- editible name -->
                        <div>
                            <h1 v-if="!editingName" @click="startEditingName()" class="text-2xl border-2 border-transparent">{{ songName }}
                                <svg class="inline-block h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg></h1>
                            <input v-focus v-if="editingName" v-model="songName" v-on:keypress.enter="updateSongName()" @blur="updateSongName()" class="text-2xl bg-transparent border-2 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500"/>
                        </div>

                        <!-- buttons -->
                        <div class="flex items-center space-x-2">
                            <button
                                class="inline-flex items-center px-4 py-2 border border-gray-400 text-gray-400 font-medium focus:outline-none"
                                @click="edit()">
                                <svg class="w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                                </svg>
                                Edit
                            </button>
                            <button
                                class="inline-flex items-center px-4 py-2 border border-black dark:border-white text-base font-medium text-black dark:text-white bg-transparent"
                                @click="saveClick()">
                                <svg v-if="saving" class="animate-spin -ml-1 mr-3 h-5 w-5 text-black dark:text-white"
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Save
                            </button>
                        </div>
                    </div>

                    <!-- JSON Editing -->
                    <div v-if="editing" class="my-6">
                        <textarea v-model="psconfigJSON" @keydown.tab.prevent="tabber($event)" @keydown.ctrl.s.prevent.stop="saveKey()"
                                  spellcheck="false"
                                  class="font-mono text-xs w-full h-96
                                 bg-gray-200 text-gray-500 dark:bg-gray-900 dark:text-gray-300 p-2 border border-gray-300 dark:border-black"></textarea>
                    </div>

                    <!-- song panel -->
                    <div class="flex flex-col">
                        <div class="shadow overflow-hidden sm:rounded-lg">

                            <div class="hidden lg:grid bg-gray-50 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-800 text-gray-400 grid-cols-10">
                                <div class="px-4 py-2 col-span-7">Key</div>
                                <div class="px-4 py-2 col-span-2">Volume</div>
                                <div class="px-4 py-2 col-span-1 ml-6">On</div>
                            </div>

                            <!-- each row -->
                            <div class="divide-y divide-gray-200 dark:divide-gray-800">
                                    <div class="each-instrument relative grid gap-4 p-4 items-center bg-white dark:bg-gray-900 grid-cols-3
                                                lg:grid-cols-10">

                                        <!-- key/type -->
                                        <div class="flex flex-wrap items-center space-x-2 col-span-2 order-1
                                                    lg:col-span-7">
                                            <select class="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm rounded-md bg-transparent" v-model="kkey" v-on:change="onKeyChanged()">
                                                <option>C</option>
                                                <option>G</option>
                                                <option>D</option>
                                                <option>A</option>
                                                <option>E</option>
                                                <option>F</option>
                                                <option>Bb</option>
                                                <option>Eb</option>
                                                <option>Ab</option>
                                                <option>B</option>
                                                <option>Cb</option>
                                                <option>F#</option>
                                                <option>Gb</option>
                                                <option>C#</option>
                                                <option>Db</option>
                                            </select>
                                            <select class="block pl-3 pr-10 py-2 max-w-xs text-base border-gray-300 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm rounded-md bg-transparent" v-model="kkeyType" v-on:change="onKeyChanged()">
                                                <option>Major</option>
                                                <option>Minor (natural)</option>
                                                <option>Minor (harmonic)</option>
                                                <option>Minor (melodic)</option>
                                            </select>
                                        </div>

                                        <!-- song volume -->
                                        <div class="col-span-3 lg:col-span-2 order-2">
                                            <div class="flex items-center justify-between space-x-4">
                                                <div class="flex-grow">
                                                    <input type="range" min="-50" max="0" step="1" class="w-full" v-model="volume" v-on:change="onVolumeChanged()"/>
                                                    <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                                        <div>-50</div>
                                                        <div>0</div>
                                                    </div>

                                                </div>
                                                <input type="text" class="block text-center w-14 rounded-md bg-gray-100 text-gray-400 dark:bg-gray-800 border border-gray-300 dark:border-gray-700" v-model="volume"/>
                                            </div>
                                        </div>

                                        <!-- master on/off -->
                                        <div class="col-span-1 text-right order-1 self-start lg:order-3">
                                            <label class="switch" id="theme-switch-distortion">
                                                <input id="theme-switch-distortion-checkbox" type="checkbox" autocomplete="false" v-model="active" v-on:change="activeChanged()">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>


                <!-- instruments -->
                <div id="instruments" class="mt-12 mb-12">

                    <div class="flex items-center justify-between mb-4">
                        <h1 class="text-2xl">Instruments</h1>
                        <div>
                            <button
                                class="duration-300 inline-flex items-center px-4 py-2 border border-green-500 text-base font-medium text-green-500 bg-transparent hover:bg-green-100 active:bg-green-200"
                                @click="onAddInstrument()">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                                Add
                            </button>
                        </div>
                    </div>

                    <div class="flex flex-col">
                        <div class="shadow overflow-hidden sm:rounded-lg">

                            <div class="hidden bg-gray-50 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-800 text-gray-400 grid-cols-10
                                           lg:grid">
                                <div class="px-4 py-2 col-span-3">Instrument</div>
                                <div class="px-4 py-2 col-span-2">Summary</div>
                                <div class="px-4 py-2 col-span-2">Listening To</div>
                                <div class="px-4 py-2 col-span-2">Volume</div>
                                <div class="px-4 py-2 col-span-1 ml-6">On</div>
                            </div>

                            <!-- each row -->
                            <div class="divide-y divide-gray-200 dark:divide-gray-800">
                                <template v-for="eachId in instrumentIds" :key="eachId">
                                    <Instrument class="each-instrument relative grid gap-2 p-4 items-center bg-white dark:bg-gray-900 grid-cols-3
                                                           lg:grid-cols-10" :instrumentId="eachId"></Instrument>
                                </template>
                            </div>
                        </div>

                    </div>

                </div>


            </div>


            <!-- instrument editor -->
            <!-- this wrapper transition is required to keep the wrapper alive long enough to see the internal transitions -->
            <transition
                name="discord-transition-1"
                enter-active-class="ease-out duration-500"
                enter-from-class="opacity-100"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-500"
                leave-from-class="opacity-100"
                leave-to-class="opacity-100">

                <div class="z-10 fixed inset-0 overflow-hidden transition-opacity" v-show="showInstrumentEditor">
                <div class="absolute inset-0 overflow-hidden">

                    <transition
                        name="discord-transition-1"
                        enter-active-class="ease-out duration-300"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="ease-in duration-200"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                        <div v-show="showInstrumentEditor" class="absolute inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                    </transition>

                    <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex" aria-labelledby="slide-over-heading">

                        <transition
                            name="edit-instrument-transition"
                            enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from-class="translate-x-full"
                            enter-to-class="translate-x-0"
                            leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from-class="translate-x-0"
                            leave-to-class="translate-x-full">
                            <div v-show="showInstrumentEditor" class="w-screen max-w-md transform transition-all">
                                <EditInstrument></EditInstrument>
                            </div>
                        </transition>

                    </section>
                </div>
            </div>
            </transition>
        </div>

    </div>

</template>


<script>
import SideBar from "@/components/SideBar";
import Instrument from "@/components/Instrument";
import EditInstrument from "@/components/EditInstrument";
import * as Tone from 'tone';
import * as Pixelsong from "@/player/index.js";
import * as Vue from 'vue'
import { nextTick } from 'vue'


export default {
    name: "Dashboard.vue",
    inject: ['axios','player','emitter'],
    components: {SideBar, Instrument, EditInstrument},
    data() {
        return {
            websiteId: null,
            saving: false,
            psd2 : new Pixelsong.PsData2(),
            doc: {},
            psconfigJSON: "",
            editing: false,
            songId: this.$route.params.songid,

            showInstrumentEditor: false,
            instrumentIds: [],

            // don't use 'key' as the name with vue
            kkey : String,
            kkeyType : Pixelsong.KeyType,
            volume : 0.0,
            active: true,

            songName : '',
            editingName: false,
            isDemo : false,
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.user;
        },

        instruments() {
            return this.player.instruments;
        }
    },


    mounted() {
        if (!this.isDemo && !this.currentUser) {
            this.$store.dispatch('relogin')
                .then(() => {this.initialize();})
                .catch(err => {this.$router.push('/login');});
        } else {this.initialize();}
    },

    unmounted() {
        this.cleanup();
    },

    directives: {
        focus: {mounted(el) {el.focus()}}
    },

    methods: {
        initialize() {

            if(this.isMobile()) {
                this.$store.dispatch('error',{title:'Mobile device detected', subtitle:'Many mobile devices cannot render the songs without crackling & noise.  Some effects and options are disabled on mobile devices.'})
            }

            if(!this.websiteId) this.websiteId = this.$store.state.account.websiteId;
            this.psd2.clear();
            this.load();
            window.addEventListener('beforeunload', this.onWindowUnload);
            Tone.Master.mute = !this.active;
            this.connectListeners();
        },

        // ---------------------
        // Loading from database
        // ---------------------
        load() {
            this.axios.get('/song/' + this.songId, {
                params: {}
            })
            .then(response => {
                this.doc = response.data;
                const config = response.data.config;
                this.psconfigJSON = this.createJSONString(config);

                try {
                    this.startPlayer(config);
                } catch (e) {
                    console.error('error starting player', e)
                }
            })
            .catch(error => {
                console.error('get song error: ' + error);
                if(error.response && error.response.status == 404) {
                    this.$store.dispatch('error', {title:'Song load failed', subtitle:'Cannot find song with id ' + this.songId});
                }
                else {
                    this.$store.dispatch('error', {title:'Song load failed', subtitle:'Cannot connect to song database'});
                }
            })
            .then(function () {

            });
        },



        // -------------------
        // Saving to database
        // -------------------
        saveClick() {
            // Clicking save button does one of two options
            // depending on if the user is editing the JSON
            if (this.editing) {
                this.commitEdit(true);
            } else {
                this.save();
            }
        },

        saveKey() {
            // Ctrl-S saves the current JSON
            console.log('Saving [Ctrl-S]');
            this.commitEdit(true)
            this.editing = true;
        },

        save() {
            // save the current state
            console.log('Saving [Button]');
            const newConfig = this.player.generateConfig();
            this.saveToDatabase(newConfig,true);
        },

        commitEdit(closeOnSave) {
            try {
                console.log('Saving [Edit]');
                console.log('parsing', this.psconfigJSON);
                const newConfig = JSON.parse(this.psconfigJSON);

                this.player.loadConfig(newConfig);
                this.saveToDatabase(newConfig,closeOnSave);
            } catch (e) {
                console.error('Error reloading player with new config', e);
                this.onErrorMessage({title:'Error reloading player with new config', subtitle:e})
            }
        },

        saveToDatabase(config,closeOnSave) {
            this.saving = true;
            this.doc.config = config;

            console.debug('saving doc', this.doc);
            this.axios.put('/song', this.doc, {
                params: {crossdomain: true}
            })
                .then(response => {
                    console.info('save response', response);
                    setTimeout(() => {
                        this.saving = false;
                        this.editing = !closeOnSave;
                    }, 1000);
                })
                .catch(error => {
                    console.error('save error', error);
                    this.saving = false;
                });
        },



        // -----------------------
        // Controlling the player
        // -----------------------
        devInit() {
            // auto-on instrument editing
            //console.log('devinit',this.player.instruments);
            //this.onEditInstrument(this.player.instruments[0]);
        },

        startPlayer(config) {
            this.player.init('player-1', this.websiteId, this.emitter, this.psd2, this.isMobile());
            this.player.loadConfig(config);
            this.initializeForPlayer();
            this.player.connect();
        },

        initializeForPlayer() {
            this.songName = this.player.name;
            this.devInit()
        },




        // -----------
        // Key changes
        // -----------
        onKeyUpdated() {
            // bound to emitter event
            this.kkey = Pixelsong.PsKey.key;
            this.kkeyType = Pixelsong.PsKey.keyType;
        },

        onKeyChanged() {
            this.player.updateKey(this.kkey,this.kkeyType);
        },


        // --------------
        // Mute / Volume
        // --------------
        activeChanged() {
            Tone.Master.mute = !this.active;
        },

        onVolumeUpdated() {
            this.volume = this.player.volume;
        },

        onVolumeChanged() {
            this.player.updateVolume(this.volume);
        },


        // -------------
        // JSON Editing
        // -------------
        edit() {
            this.editing = !this.editing;
            //this.psconfigJSON = JSON.stringify(this.psconfig,null,4);
            this.psconfigJSON = this.createJSONString(this.player.generateConfig());
        },

        createJSONString(obj) {
            const json = JSON.stringify(obj, null, 4);
            return json;
        },


        // ---------
        // Listeners
        // ---------
        connectListeners() {
            this.emitter.on('error',this.onErrorMessage);
            this.emitter.on('error-remove',this.onErrorMessageRemoved);
            this.emitter.on('edit-instrument',this.onEditInstrument);
            this.emitter.on('edit-instrument-cancel',this.onCancelEditInstrument);
            this.emitter.on('edit-instrument-save',this.onSaveEditInstrument);
            this.emitter.on('edit-instrument-delete',this.onDeleteInstrument);
            this.emitter.on('key-updated', this.onKeyUpdated);
            this.emitter.on('volume-updated', this.onVolumeUpdated);
            this.emitter.on('player-instrument-added', this.onPlayerInstrumentAdded);
            this.emitter.on('player-instrument-removed', this.onPlayerInstrumentRemoved);
            this.emitter.on('player-instrument-swapped', this.onPlayerInstrumentSwapped);
        },

        disconnectListeners() {
            this.emitter.off('error',this.onErrorMessage);
            this.emitter.off('error-remove',this.onErrorMessageRemoved);
            this.emitter.off('edit-instrument',this.onEditInstrument);
            this.emitter.off('edit-instrument-cancel',this.onCancelEditInstrument);
            this.emitter.off('edit-instrument-save',this.onSaveEditInstrument);
            this.emitter.off('edit-instrument-delete',this.onDeleteInstrument);
            this.emitter.off('volume-updated', this.onVolumeUpdated);
            this.emitter.on('player-instrument-added', this.onPlayerInstrumentAdded);
            this.emitter.on('player-instrument-removed', this.onPlayerInstrumentRemoved);
            this.emitter.on('player-instrument-swapped', this.onPlayerInstrumentSwapped);
        },


        // ---------------
        // Error messaging
        // ---------------
        onErrorMessage(e) {
            this.$store.dispatch('error',e);
        },

        onErrorMessageRemoved(e) {
            this.$store.dispatch('errorRemove',e);
        },

        // --------
        // Cleanup
        // --------
        onWindowUnload(e) {
            console.debug("Window closed, cleaning up pixelsong components. " + e)
            this.cleanup();
        },

        cleanup() {
            console.info("Pixelsong cleaning up.")
            this.player.cleanup();
            this.disconnectListeners();
        },


        // -------------
        // Name editing
        // -------------
        startEditingName() {
            this.editingName = true;
        },

        updateSongName() {
            this.editingName = false;
            this.player.name = this.songName;
        },


        // ----------------
        // player callbacks
        // ----------------
        onPlayerInstrumentAdded(instrument) {
            this.updateInstrumentIds();
        },

        onPlayerInstrumentRemoved(instrument) {
            this.updateInstrumentIds();
        },

        onPlayerInstrumentSwapped(oldInstrument,newInstrument) {
            this.updateInstrumentIds();
        },

        updateInstrumentIds() {
            this.instrumentIds = this.player.getInstrumentIds();
        },

        // ------------------
        // instrument editing
        // ------------------
        onEditInstrument(instrument) {
            this.showInstrumentEditor = true;
            this.emitter.emit('initialize-instrument-editor', instrument.id);
        },

        onAddInstrument() {
            this.showInstrumentEditor = true;
            this.emitter.emit('initialize-instrument-editor', null);
        },

        onCancelEditInstrument() {
            this.showInstrumentEditor = false;
        },

        onSaveEditInstrument() {
            this.showInstrumentEditor = false;
        },

        onDeleteInstrument() {
            this.showInstrumentEditor = false;
        },



        // ----------------------------------
        // Custom tab handler for json editor
        // ----------------------------------
        tabber(event) {
            let text = this.psconfigJSON,
                originalSelectionStart = event.target.selectionStart,
                textStart = text.slice(0, originalSelectionStart),
                textEnd = text.slice(originalSelectionStart);

            this.psconfigJSON = `${textStart}  ${textEnd}`
            event.target.value = this.psconfigJSON // required to make the cursor stay in place.
            event.target.selectionEnd = event.target.selectionStart = originalSelectionStart + 2
        },

        isMobile() {
            var check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4)))
                    check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        }
    }
}
</script>

<style>
.each-instrument > * {

}
</style>
