<template>

    <div class="h-full divide-y divide-gray-200 flex flex-col bg-white dark:bg-gray-900 shadow-xl">
        <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll" ref="instrumentEditorScrollableRegion">
            <div class="px-4 sm:px-6 border-b pb-4">


                <!-- upper info bar with close(x) -->
                <div class="flex items-start justify-between">

                    <div class="flex items-center space-x-4">
                        <svg class="w-8 h-8" height="512pt" viewBox="-46 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="linear0" gradientUnits="userSpaceOnUse" x1="210" x2="210" y1="0" y2="512"><stop offset="0" stop-color="#2af598"/><stop offset="1" stop-color="#009efd"/></linearGradient><path d="m378.347656 136-136.800781-85.820312c2.210937-4.589844 3.453125-9.742188 3.453125-15.179688 0-19.328125-15.671875-35-35-35s-35 15.671875-35 35c0 5.464844 1.253906 10.632812 3.484375 15.242188l-135.851563 85.757812h-42.632812v40h420v-40zm-168.316406-58.367188 93.042969 58.367188h-185.507813zm-100.03125 137.367188h40v297h-40zm80 0h40v257h-40zm-160 1h40v216h-40zm240 0h40v216h-40zm81-1h40v177h-40zm0 0" fill="url(#linear0)"/></svg>
                        <div>
                            <div class="capitalize font-bold">{{ type }}</div>
                            <div>{{ name }}</div>
                        </div>
                    </div>
                    <div class="ml-3 h-7 flex items-center">
                        <button v-on:click="cancel()" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-yellow-500">
                            <span class="sr-only">Close panel</span>
                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>
                    </div>

                </div>
            </div>


            <!-- widget container -->
            <div class="mt-6 relative flex-1 px-4 sm:px-6">



                <!-- Name -->
                <div class="font-bold">Name</div>
                <input type="text" v-model="name" class="border w-full p-2 dark:bg-gray-900 border-light-blue-500"/>



                <!-- Listen To -->
                <div style="grid-template-columns: auto 1fr;" class="grid items-center gap-4 mt-4">
                    <div class="font-bold">Listen To</div>
                    <div></div>

                    <span>Event</span>
                    <select class="border w-full p-2 dark:bg-gray-900 border-light-blue-500" v-model="subscription.name" v-on:change="onListenToChanged()">
                        <option value="all">All events</option>
                        <option value="pageload">Pageload</option>
                        <option value="click">Click</option>
                    </select>

                    <span>Path</span>
                    <input type="text" class="border w-full p-2 dark:bg-gray-900 border-light-blue-500" list="seenPaths" v-model="subscription.url" v-on:blur="onListenToChanged()"/>
                    <datalist id="seenPaths">
                        <option v-for="item in psd2.paths" :key="item">{{ item }}</option>
                    </datalist>


                    <span>Title</span>
                    <input type="text" class="border w-full p-2 dark:bg-gray-900 border-light-blue-500" list="seenTitles" v-model="subscription.title" v-on:blur="onListenToChanged()"/>
                    <datalist id="seenTitles">
                        <option v-for="item in psd2.titles" :key="item">{{ item }}</option>
                    </datalist>

                    <template v-if="subscription.name!='pageload'">
                        <span>Text</span>
                        <input type="text" class="border w-full p-2 dark:bg-gray-900 border-light-blue-500" list="seenTexts" v-model="subscription.text" v-on:blur="onListenToChanged()"/>
                        <datalist id="seenTexts">
                            <option v-for="item in psd2.texts" :key="item">{{ item }}</option>
                        </datalist>
                    </template>

                    <template v-if="subscription.name!='pageload'">
                        <span>Tag</span>
                        <select class="border w-full p-2 dark:bg-gray-900 border-light-blue-500" v-model="subscription.tag" v-on:change="onListenToChanged()">
                            <option value="all">Any</option>
                            <option value="button">Button</option>
                            <option value="a">Link (a)</option>
                        </select>
                    </template>

                    <template v-if="subscription.name!='pageload'">
                        <span>Element Id</span>
                        <input type="text" class="border w-full p-2 dark:bg-gray-900 border-light-blue-500" list="seenIds" v-model="subscription.id" v-on:blur="onListenToChanged()"/>
                        <datalist id="seenIds">
                            <option v-for="item in psd2.ids" :key="item">{{ item }}</option>
                        </datalist>
                    </template>
                </div>


                <!-- Volume -->
                <div class="font-bold mt-6">Volume</div>
                <div class="flex items-center justify-between space-x-4">
                    <div class="flex-grow">
                        <input type="range" min="-50" max="0" step="1" class="w-full" v-model="volume" v-on:change="onVolumeChanged()"/>
                        <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                            <div>-50</div>
                            <div>0</div>
                        </div>

                    </div>
                    <input type="text" class="border w-12 p-2 dark:bg-gray-900 border-light-blue-500" v-model="volume"/>
                </div>



                <!-- Range -->
                <div class="font-bold mt-6">Octave Range</div>
                <span class="relative mt-2 flex shadow-sm rounded-md justify-between">
                    <button type="button" class="flex-1 relative items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium bg-transparent"
                            :class="[notegenRange[range2] ? 'bg-light-blue-500 text-white' : '']"
                            @click="onRangeClicked(range2)">
                        2
                    </button>

                   <button type="button"
                           class="flex-1 -ml-px relative items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:outline-none bg-transparent"
                           :class="[notegenRange[range3] ? 'bg-light-blue-500 text-white' : '']"
                           @click="onRangeClicked(range3)">
                        3
                    </button>

                    <button type="button"
                            class="flex-1 -ml-px relative items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:outline-none bg-transparent"
                            :class="[notegenRange[range4] ? 'bg-light-blue-500 text-white' : '']"
                            @click="onRangeClicked(range4)">
                        4
                    </button>

                    <button type="button"
                            class="flex-1 -ml-px relative items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:outline-none bg-transparent"
                            :class="[notegenRange[range5] ? 'bg-light-blue-500 text-white' : '']"
                            @click="onRangeClicked(range5)">
                        5
                    </button>

                    <button type="button"
                            class="flex-1 -ml-px relative items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:outline-none bg-transparent"
                            :class="[notegenRange[range6] ? 'bg-light-blue-500 text-white' : '']"
                            @click="onRangeClicked(range6)">
                        6
                    </button>

                    <button type="button" class="flex-1 -ml-px relative items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium bg-transparent"
                            :class="[notegenRange[range7] ? 'bg-light-blue-500 text-white' : '']"
                            @click="onRangeClicked(range7)">
                        7
                    </button>
                </span>



                <!-- Notes/Chords -->
                <div class="font-bold mt-6">Plays</div>
                <span class="relative mt-2 flex shadow-sm rounded-md">
                    <button type="button" class="inline-flex relative items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium bg-transparent"
                            :class="[notegenType == 'note' ? 'bg-light-blue-500 text-white' : '']"
                            @click="onNotegenTypeClicked('note')">
                        Notes
                    </button>

                    <button type="button" class="inline-flex -ml-px relative items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium bg-transparent"
                            :class="[notegenType == 'chord' ? 'bg-light-blue-500 text-white' : '']"
                            @click="onNotegenTypeClicked('chord')">
                        Chords
                    </button>
                </span>



                <!-- seperater -->
                <div class="mt-12 mb-12 border-t border-gray-200"></div>



                <!-- Oscillator -->
                <div style="grid-template-columns: auto 1fr;" class="grid items-center gap-4">
                    <div class="font-bold">Oscillator</div>
                    <div></div>

                    <span>Type</span>
                    <select v-model="oscillatorType" @change="onOscillatorChanged()" class="border w-full p-2 dark:bg-gray-900 border-light-blue-500">
                        <option>sine</option>
                        <option>square</option>
                        <option>sawtooth</option>
                        <option>triangle</option>
                        <option>fatsawtooth</option>
                    </select>
                </div>



                <!-- seperater -->
                <div class="mt-12 mb-12 border-t border-gray-200"></div>



                <!-- Envelope -->
                <div style="grid-template-columns: auto 1fr;" class="grid items-center gap-4">
                    <div class="font-bold">Envelope</div>
                    <div></div>

                    <span>Attack</span>
                    <div class="flex items-center justify-between space-x-4">
                        <div class="flex-grow">
                            <input type="range" min="0" max="5" step=".01" class="w-full" v-model="envelopeAttack" v-on:change="onEnvelopeChanged()"/>
                            <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                <div>0</div>
                                <div>5</div>
                            </div>
                        </div>
                        <input type="text" class="block text-center w-14 rounded-md bg-gray-100 text-gray-400 dark:bg-gray-800 border border-gray-300 dark:border-gray-700"
                               v-model="envelopeAttack"/>
                    </div>

                    <span>Decay</span>
                    <div class="flex items-center justify-between space-x-4">
                        <div class="flex-grow">
                            <input type="range" min="0" max="1" step=".1" class="w-full" v-model="envelopeDecay" v-on:change="onEnvelopeChanged()"/>
                            <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                <div>0</div>
                                <div>1</div>
                            </div>

                        </div>
                        <input type="text" class="block text-center w-14 rounded-md bg-gray-100 text-gray-400 dark:bg-gray-800 border border-gray-300 dark:border-gray-700"
                               v-model="envelopeDecay"/>
                    </div>

                    <span>Sustain</span>
                    <div class="flex items-center justify-between space-x-4">
                        <div class="flex-grow">
                            <input type="range" min="0" max="1" step=".1" class="w-full" v-model="envelopeSustain" v-on:change="onEnvelopeChanged()"/>
                            <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                <div>0</div>
                                <div>1</div>
                            </div>

                        </div>
                        <input type="text" class="block text-center w-14 rounded-md bg-gray-100 text-gray-400 dark:bg-gray-800 border border-gray-300 dark:border-gray-700"
                               v-model="envelopeSustain"/>
                    </div>


                    <span>Release</span>
                    <div class="flex items-center justify-between space-x-4">
                        <div class="flex-grow">
                            <input type="range" min="0" max="20" step=".1" class="w-full" v-model="envelopeRelease" v-on:change="onEnvelopeChanged()"/>
                            <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                <div>0</div>
                                <div>20</div>
                            </div>

                        </div>
                        <input type="text" class="block text-center w-14 rounded-md bg-gray-100 text-gray-400 dark:bg-gray-800 border border-gray-300 dark:border-gray-700"
                               v-model="envelopeRelease"/>
                    </div>
                </div>



                <!-- seperater -->
                <div class="mt-12 mb-12 border-t border-gray-200"></div>



                <!-- effects -->
                <template v-if="!isMobile()">

                <!-- Distortion -->
                <div style="grid-template-columns: auto 1fr;" class="grid items-center gap-4">
                    <span class="font-bold mr-4">Distortion</span>
                    <label class="switch" id="theme-switch-distortion">
                        <input id="theme-switch-distortion-checkbox" type="checkbox" autocomplete="false" v-model="isDistortionOn" v-on:change="onDistortionToggled()">
                        <span class="slider round"></span>
                    </label>

                    <span>Wet</span>
                    <div class="flex items-center justify-between space-x-4">
                        <div class="w-full">
                            <input type="range" min="0" max="1" step=".1" class="w-full" v-model="distortionWet" v-on:change="onDistortionChanged"/>
                            <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                <div>0</div>
                                <div>1</div>
                            </div>
                        </div>
                        <input type="text" class="border w-12 p-2 dark:bg-gray-900 border-light-blue-500" v-model="distortionWet"/>
                    </div>

                    <span>Amount</span>
                    <div class="flex items-center justify-between space-x-4">
                        <div class="w-full">
                            <input type="range" min="0" max="1" step=".01" class="w-full" v-model="distortion" v-on:change="onDistortionChanged"/>
                            <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                <div>1</div>
                                <div>1</div>
                            </div>
                        </div>
                        <input type="text" class="border w-12 p-2 dark:bg-gray-900 border-light-blue-500" v-model="distortion"/>

                    </div>
                </div>



                <!-- seperater -->
                <div class="mt-12 mb-12 border-t border-gray-200"></div>



                <!-- Delay -->
                <div style="grid-template-columns: auto 1fr;" class="grid items-center gap-4">
                    <span class="font-bold mr-4">Delay</span>
                    <label class="switch" id="theme-switch">
                        <input id="theme-switch-checkbox" type="checkbox" autocomplete="false" v-model="isDelayOn" v-on:change="onDelayToggled()">
                        <span class="slider round"></span>
                    </label>

                    <span>Wet</span>
                    <div class="flex items-center justify-between space-x-4">
                        <div class="w-full">
                            <input type="range" min="0" max="1" step=".1" class="w-full" v-model="delayWet" v-on:change="onDelayChanged"/>
                            <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                <div>0</div>
                                <div>1</div>
                            </div>
                        </div>
                        <input type="text" class="border w-12 p-2 dark:bg-gray-900 border-light-blue-500" v-model="delayWet"/>
                    </div>

                    <span>Time</span>
                    <input type="text" v-model="delayTime" class="border w-full p-2 dark:bg-gray-900 border-light-blue-500" @blur="onDelayChanged()"/>

                    <span>Feedback</span>
                    <div class="flex items-center justify-between space-x-4">
                        <div class="w-full">
                            <input type="range" min="0" max="1" step=".01" class="w-full" v-model="delayFeedback" v-on:change="onDelayChanged"/>
                            <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                <div>0</div>
                                <div>1</div>
                            </div>
                        </div>
                        <input type="text" class="border w-12 p-2 dark:bg-gray-900 border-light-blue-500" v-model="delayFeedback"/>

                    </div>
                </div>



                <!-- seperater -->
                <div class="mt-12 mb-12 border-t border-gray-200"></div>



                <!-- Reverb -->
                <div style="grid-template-columns: auto 1fr;" class="grid items-center gap-4">
                    <span class="font-bold mr-4">Reverb</span>
                    <label class="switch" id="theme-switch-reverb">
                        <input id="theme-switch-reverb-checkbox" type="checkbox" autocomplete="false" v-model="isReverbOn" v-on:change="onReverbToggled()">
                        <span class="slider round"></span>
                    </label>

                    <span>Wet</span>
                    <div class="flex items-center justify-between space-x-4">
                        <div class="w-full">
                            <input type="range" min="0" max="1" step=".1" class="w-full" v-model="reverbWet" v-on:change="onReverbChanged"/>
                            <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                <div>0</div>
                                <div>1</div>
                            </div>
                        </div>
                        <input type="text" class="border w-12 p-2 dark:bg-gray-900 border-light-blue-500" v-model="reverbWet"/>
                    </div>

                    <span>Room Size</span>
                    <div class="flex items-center justify-between space-x-4">
                        <div class="w-full">
                            <input type="range" min="0" max="1" step=".01" class="w-full" v-model="reverbRoomSize" v-on:change="onReverbChanged"/>
                            <div class="flex justify-between -mt-3 text-gray-400" style="font-size:0.5em">
                                <div>0</div>
                                <div>1</div>
                            </div>
                        </div>
                        <input type="text" class="border w-12 p-2 dark:bg-gray-900 border-light-blue-500" v-model="reverbRoomSize"/>

                    </div>
                </div>
                </template>


                <div class="h-28"></div>

            </div>
        </div>


        <!-- bottom buttons -->
        <div class="flex items-center justify-between flex-wrap p-4 space-x-4">
            <button class="duration-300 inline-flex items-center px-4 py-2 border bg-green-500 border-green-500 active:bg-green-700 hover:bg-green-600 text-white font-medium" @click="trigger()">Trigger</button>
            <div class="flex-shrink-0 flex justify-end space-x-2">
                <button class="duration-300 inline-flex items-center px-4 py-2 border border-red-400 text-red-400 hover:bg-red-100 font-medium focus:outline-none" v-if="!adding" @click="onDelete()">Delete</button>
                <button class="duration-300 inline-flex items-center px-4 py-2 border border-gray-400 text-gray-400 font-medium focus:outline-none" @click="cancel()">Cancel</button>
                <button class="duration-300 inline-flex items-center px-4 py-2 border bg-light-blue-500 border-light-blue-500 hover:bg-light-blue-600 text-white font-medium focus:outline-none" @click="save()">Accept</button>
            </div>
        </div>


    </div>


</template>

<script>
var originalInstrument = null;
var instrument = null;

import * as Pixelsong from "@/player/index.js";
import {v4 as uuidv4} from 'uuid';
import { nextTick } from 'vue'

export default {
    name: "EditInstrument",
    components: {},
    inject: ['player', 'emitter'],
    props: {

    },

    data() {
        return {
            instrumentId : null,
            psd2: {},
            entries: [],
            name: null,
            type: null,
            listenTo: null,
            oscillatorType: '',
            originalInstrumentOnSetting: true,
            adding: false,
            volume: -10.0,
            subscription : {},
            notegenType: 'note',
            notegenRange: [false,false,true,true,false,false],
            range2 : 0,
            range3 : 1,
            range4 : 2,
            range5 : 3,
            range6 : 4,
            range7 : 5,

            envelopeAttack : 1,
            envelopeDecay : 1,
            envelopeSustain : 1,
            envelopeRelease : 1,

            isDistortionOn: false,
            distortionWet: 0,
            distortion: 0.0,
            isDelayOn: false,
            delayWet: 0,
            delayTime: '',
            delayFeedback: 0.0,
            isReverbOn: false,
            reverbWet: 0,
            reverbRoomSize: 0.1,
        }
    },

    computed: {},

    mounted() {
        this.emitter.on('initialize-instrument-editor',this.onInitialize)
    },

    unmounted() {
        this.emitter.off('initialize-instrument-editor',this.onInitialize)
    },

    methods: {

        onInitialize(instrumentId) {
            this.instrumentId = instrumentId;
            let config = null;
            this.psd2 = this.player.psd2;

            if (this.instrumentId == null) {
                this.adding = true;
                config = {
                    // default settings for instrument
                    id: uuidv4(),
                    name: 'New Instrument',
                    type: "windchime",
                    listenTo: "",
                    subscription: {
                        name: "pageload",
                        url: "",
                        tag: "",
                        id: "",
                        text: "",
                        title: ""
                    },
                    isOn: true,
                    volume: '-10',
                    windchime: {
                        duration: '8n',
                        notegen: {
                            specific: 'off',
                            type: 'note',
                            key: 'B',
                            keyType: "major",
                            range: [5, 6]
                        },
                    },
                    delay: {
                        wet: 0.0
                    },
                    reverb: {
                        wet: 0.0
                    }
                };
            } else {
                this.adding = false;
                originalInstrument = this.player.getInstrument(this.instrumentId);
                config = originalInstrument.generateConfig();
                originalInstrument.off();
            }

            // ------------------------------------------
            // add the temporary instrument to the player
            // ------------------------------------------
            instrument = this.player.copyInstrument(config)
            instrument.on();
            this.player.wireExternalInstrument(instrument);

            config = instrument.generateConfig();
            console.info('edit config updated to new instrument generated config', config);

            // copy the variables we care about for the UI
            this.name = config.name;
            this.type = config.type;
            this.volume = config.volume;
            this.listenTo = config.listenTo;
            this.oscillatorType = config.windchime.oscillator.type;
            this.originalInstrumentOnSetting = config.isOn;

            this.subscription = config.subscription;

            this.envelopeAttack  = config.windchime.envelope.attack;
            this.envelopeDecay  = config.windchime.envelope.decay;
            this.envelopeSustain  = config.windchime.envelope.sustain;
            this.envelopeRelease = config.windchime.envelope.release;

            // note gen
            this.notegenType = config.windchime.notegen.type;
            this.notegenRange[this.range2] = config.windchime.notegen.range.includes(2);
            this.notegenRange[this.range3] = config.windchime.notegen.range.includes(3);
            this.notegenRange[this.range4] = config.windchime.notegen.range.includes(4);
            this.notegenRange[this.range5] = config.windchime.notegen.range.includes(5);
            this.notegenRange[this.range6] = config.windchime.notegen.range.includes(6);
            this.notegenRange[this.range7] = config.windchime.notegen.range.includes(7);

            //effects
            this.isDistortionOn = config.distortion.wet > 0;
            this.distortionWet = config.distortion.wet;
            this.distortion = config.distortion.distortion;
            this.isDelayOn = config.delay.wet > 0;
            this.delayWet = config.delay.wet;
            this.delayTime = config.delay.time;
            this.delayFeedback = config.delay.feedback;
            this.isReverbOn = config.reverb.wet > 0;
            this.reverbWet = config.reverb.wet;
            this.reverbRoomSize = config.reverb.roomSize;

            nextTick(() => {
                // otherwise the editor remembers it's position after you close it.
                this.$refs.instrumentEditorScrollableRegion.scrollTop = 0;
            })
        },

        save() {
            if (this.adding) {
                this.recreateInternalInstrument();
                this.player.addInstrument(instrument);
                this.emitter.emit('edit-instrument-save', {});
            } else {
                this.recreateInternalInstrument();
                this.player.swapInstrument(originalInstrument, instrument);
                this.emitter.emit('edit-instrument-save', {});
            }
        },

        cancel() {
            if (originalInstrument) originalInstrument.toggle(this.originalInstrumentOnSetting);  // restore original on/off
            instrument.off();
            this.player.deleteInstrument(instrument);
            this.emitter.emit('edit-instrument-cancel', {});
        },

        onDelete() {
            instrument.off();
            this.player.deleteInstrument(instrument);
            originalInstrument.off();
            this.player.deleteInstrument(originalInstrument, true);
            this.emitter.emit('edit-instrument-delete', {});
        },

        trigger() {
            instrument.manualTrigger();
        },

        generateConfig() {
            const config = instrument.generateConfig();
            config.name = this.name;
            config.type = this.type;
            config.listenTo = this.listenTo;
            config.windchime.oscillator.type = this.oscillatorType;

            config.windchime.envelope.attack = this.envelopeAttack;
            config.windchime.envelope.sustain = this.envelopeSustain;
            config.windchime.envelope.decay = this.envelopeDecay;
            config.windchime.envelope.release = this.envelopeRelease;

            if(this.notegenType == 'note') config.windchime.notegen.type = 'note';
            else if(this.notegenType == 'chord') config.windchime.notegen.type = 'chord';
            else config.windchime.notegen.type = 'note';

            const range = [];
            if(this.notegenRange[this.range2]) range.push(2);
            if(this.notegenRange[this.range3]) range.push(3);
            if(this.notegenRange[this.range4]) range.push(4);
            if(this.notegenRange[this.range5]) range.push(5);
            if(this.notegenRange[this.range6]) range.push(6);
            if(this.notegenRange[this.range7]) range.push(7);
            config.windchime.notegen.range = range;

            config.distortion.wet = this.distortionWet;
            config.distortion.distortion = this.distortion;
            config.delay.wet = this.delayWet;
            config.delay.time = this.delayTime;
            config.delay.feedback = this.delayFeedback;
            config.reverb.wet = this.reverbWet;
            config.reverb.roomSize = this.reverbRoomSize;
            return config;
        },

        onVolumeChanged() {
            instrument.setVolume(this.volume);
        },

        onListenToChanged() {

            if(this.subscription.name == 'pageload') {
                this.subscription.tag = 'all';
                this.subscription.id = '';
                this.subscription.text = '';
            }

            instrument.updateListenTo(this.listenTo);
            instrument.updateSubscription(this.subscription);
        },

        onRangeClicked(val) {
            this.notegenRange[val] = !this.notegenRange[val];
            if(!this.notegenRange.includes(true)) this.notegenRange[this.range3] = true;
            this.recreateInternalInstrument();
        },

        onNotegenTypeClicked(val) {
            this.notegenType = val;
            this.recreateInternalInstrument();
        },

        onOscillatorChanged() {
            if (this.type == 'windchime') {
                console.log('oscillator changed', this.oscillatorType);
                this.recreateInternalInstrument();
            }
        },

        onEnvelopeChanged() {
            if (this.type == 'windchime') {
                console.log('envelope changed', this.envelopeAttack, this.envelopeSustain, this.envelopeDecay, this.envelopeRelease);
                //this.recreateInternalInstrument();
                instrument.updateEnvelope(this.envelopeAttack, this.envelopeSustain, this.envelopeDecay, this.envelopeRelease);
            }
        },

        onDistortionToggled() {
            if (this.isDistortionOn) {
                this.distortionWet = 1.0;
            } else this.distortionWet = 0.0;
            instrument.updateDistortion(this.distortionWet, this.distortion);
        },

        onDelayToggled() {
            if (this.isDelayOn) {
                this.delayWet = 1.0;
            } else this.delayWet = 0.0;
            instrument.updateDelay(this.delayWet, this.delayTime, this.delayFeedback);
        },

        onReverbToggled() {
            if (this.isReverbOn) {
                this.reverbWet = 0.1;
                this.reverbRoomSize = 0.9;
            } else this.reverbWet = 0.0;
            instrument.updateReverb(this.reverbWet, this.reverbRoomSize);
        },

        onDistortionChanged() {
            this.isDistortionOn = this.distortionWet > 0;
            instrument.updateDistortion(this.distortionWet, this.distortion);
        },

        onDelayChanged() {
            this.isDelayOn = this.delayWet > 0;
            instrument.updateDelay(this.delayWet, this.delayTime, this.delayFeedback);
        },

        onReverbChanged() {
            this.isReverbOn = this.reverbWet > 0;
            instrument.updateReverb(this.reverbWet, this.reverbRoomSize);
        },

        recreateInternalInstrument() {
            instrument.off();
            this.player.deleteInstrument(instrument,true);
            const newConfig = this.generateConfig();
            newConfig.id = uuidv4(); // this is important to make vue.js re-render
            console.log('recreating internal instrument with config', newConfig);
            instrument = this.player.copyInstrument(newConfig);
            instrument.on();
        },

        isMobile() {
            var check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4)))
                    check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        }
    }
}
</script>

<style scoped>

</style>
