var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as Tone from "tone";
import { PsMessage } from "../player";
import { NoteCreator } from "../notes";
import { WindchimeConfig } from "../config";
import { Instrument } from "./instruments";
var WindChime = /** @class */ (function (_super) {
    __extends(WindChime, _super);
    function WindChime(config, emitter, mobile) {
        var _this = this;
        console.debug('new windchime constructed', config);
        _this = _super.call(this, config, emitter, mobile) || this;
        _this.notegen = NoteCreator.create(_this.config.windchime.notegen);
        _this.updateSynth();
        _this.updateSummary();
        return _this;
    }
    WindChime.prototype.updateSynth = function () {
        var oscillatorConfig = this.config.windchime.oscillator;
        var envelopeConfig = this.config.windchime.envelope;
        if (!oscillatorConfig) {
            oscillatorConfig = {
                type: 'sine'
            };
        }
        if (!envelopeConfig) {
            envelopeConfig = {
                attack: 0.01,
                decay: 0.1,
                release: 0.4,
                sustain: 0.5
            };
        }
        // always a poly
        this.synth = new Tone.PolySynth(Tone.Synth, {
            oscillator: oscillatorConfig,
            envelope: envelopeConfig
        });
        this.synth.maxPolyphony = 200;
        this.synth.volume.value = this.volume;
        this.connectSynthToChain(this.synth);
    };
    WindChime.prototype.onEffectChainAltered = function () {
        if (this.synth) {
            console.info('windchime connecting synth to chain');
            this.connectSynthToChain(this.synth);
        }
    };
    WindChime.prototype.manualTrigger = function () {
        var msg = new PsMessage('name', 'msg');
        this.onHeardImpl(msg);
    };
    WindChime.prototype.generateSummary = function () {
        return this.notegen.getSummary();
    };
    WindChime.prototype.applyConfig = function (parentConfig) {
        var attr = this.synth.get();
        var wcc = new WindchimeConfig();
        wcc.duration = this.config.windchime.duration;
        wcc.notegen = this.notegen.generateConfig();
        wcc.oscillator = attr.oscillator;
        wcc.envelope = attr.envelope;
        parentConfig.windchime = wcc;
    };
    WindChime.prototype.onHeardImpl = function (msg) {
        if (this.synth && this.isOn) {
            var notes = this.notegen.next(); // array of notes
            //console.debug('windchime, playing note',notes.toString(),msg);
            this.synth.triggerAttackRelease(notes, this.config.windchime.duration);
        }
    };
    WindChime.prototype.updateEnvelope = function (a, d, s, r) {
        this.synth.set({
            envelope: {
                attack: a,
                decay: d,
                sustain: s,
                release: r
            }
        });
    };
    WindChime.prototype.setVolume = function (val) {
        this.volume = val;
        this.synth.volume.value = val;
    };
    WindChime.prototype.cleanupImpl = function () {
        this.emitter.off(this.listenTo, this.heardFunction);
    };
    WindChime.prototype.disposeImpl = function () {
        if (this.synth) {
            this.synth.dispose();
            this.synth = null;
        }
    };
    return WindChime;
}(Instrument));
export { WindChime };
