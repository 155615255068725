import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const axios = require('axios').default;
axios.defaults.baseURL = 'https://pixelsong.io:7001/api/';

const token = localStorage.getItem('token')
if (token) {axios.defaults.headers.common['Authorization'] = 'Bearer ' + token}


const getDefaultState = () => {
    return {
        token: localStorage.getItem('token') || '',
        user: null,
        account : null,
        status: '',
        errors: [],
        songs: [],
    }
}

const state = getDefaultState();

export default createStore({

    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],

    state () {
        // this allows us to clear the state on logout and reset it to default
        return state;
    },


    mutations: {

        // ------
        // Songs
        // ------
        songs(state,newSongs) {
            state.songs.length = 0;
            newSongs.forEach(i=>{
                state.songs.push(i)
            })
        },

        delete_song(state,toDelete) {
            for(let i=0; i<state.songs.length; i++) {
                const song = state.songs[i];
                if( song.id == toDelete.id) {
                    state.songs.splice(i,1);
                    return;
                }
            }
        },

        // ---------------
        // Error messaging
        // ---------------
        error(state,error) {

            let errorAlreadyInList = false;
            state.errors.forEach((i) => {
                console.log('each error', i)
                if(i.title === error.title && i.subtitle === error.subtitle)
                    errorAlreadyInList = true;
            })

            if(errorAlreadyInList) return;

            state.errors.push(error)
            if(state.errors.length >= 10) {
                state.errors.splice(0, 1)
            }
        },

        errorRemove(state,error) {
            for(let i=0; i<state.errors.length; i++) {
                const err = state.errors[i];
                if(err.title === error.title &&
                    err.subtitle === error.subtitle) {
                    state.errors.splice(i,1);
                }
            }
        },

        errorClear(state) {
            state.errors.splice(0, state.errors.length)
        },


        // --------------
        // Authentication
        // --------------
        auth_request(state) {
            state.status = 'loading'
        },

        auth_success(state, {token, user}) {
            state.status = 'success'
            state.token = token
            state.user = user
            state.account = user.account;
        },

        auth_error(state) {
            state.status = 'error'
        },

        relogin(state,user) {
            state.user = user
            state.account = user.account;
        },

        logout(state) {
            Object.assign(state, getDefaultState())
        }
    },

    actions: {

        relogin(context) {
            return new Promise((resolve, reject) => {
                if(context.state.token) {
                    axios({url: '/user/relogin/', method: 'POST' })
                        .then(resp => {
                            console.log('relogin',resp)
                            const user = resp.data;
                            context.commit('relogin',user)
                            resolve(resp);
                        })
                        .catch(err => {
                            reject(err)
                        })
                }
                else {reject();}
            })
        },


        // ---------
        // Songs
        // ---------
        getSongs(context) {
            return new Promise((resolve, reject) => {
                axios({url: '/song/?w='+context.state.account.websiteId, method: 'GET' })
                    .then(resp => {
                        const songs = resp.data
                        context.commit('songs', songs)
                        resolve(resp)
                    })
                    .catch(err => {
                        context.commit('error', {title:'Song download failed',subtitle:err.message});
                        reject(err)
                    })
            })
        },

        addSong({commit},song) {
            console.log('addSong', song)

            return new Promise((resolve, reject) => {
                axios({url: '/song/', data: song, method: 'POST' })
                    .then(resp => {
                        const rSong = resp.data;
                        song.id = rSong.id;
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('error', {title:'Song add failed',subtitle:err.message});
                        reject(err)
                    })
            })
        },

        deleteSong({commit},song) {
            return new Promise((resolve, reject) => {
                axios({url: '/song/' + song.id, method: 'DELETE' })
                    .then(resp => {
                        resolve(resp)
                        commit('delete_song',song)
                    })
                    .catch(err => {
                        commit('error', {title:'Song delete failed',subtitle:err.message});
                        reject(err)
                    })
            })
        },


        // ---------------
        // Error messaging
        // ---------------
        error({commit},error) {
            commit('error',error)
        },

        errorRemove({commit},error) {
            commit('errorRemove',error)
        },

        errorClear({commit}) {
            commit('errorClear')
        },



        // --------------
        // Authentication
        // --------------
        signup({commit},userData) {
            return new Promise((resolve, reject) => {
                commit('auth_request') // signal a message to the UI (eg: Loading...)
                axios({url: '/user/signup', data: userData, method: 'POST' })
                    .then(resp => {
                        console.log('signup successful')
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('signup error')
                        let subtitle = '';

                        if (err.response) {
                            subtitle = err.response.data;
                            //console.log(err.response.data);
                            //console.log(err.response.status);
                            //console.log(err.response.headers);
                        } else if (err.request) {
                            console.log(err.request);
                        } else {
                            console.log('Error', err.message);
                        }

                        commit('error', {title:'Signup failed',subtitle:subtitle})
                        reject(err)
                    })
            })
        },

        login({commit},userData) {
            return new Promise((resolve, reject) => {
                commit('auth_request') // signal a message to the UI (eg: Loading...)
                axios.post('/authenticate', userData)
                    .then(resp => {
                        const token = resp.data.token
                        const user = resp.data.user;
                        localStorage.setItem('token', token)
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                        commit('auth_success', {token, user})
                        resolve(resp)
                    })
                    .catch(err => {
                        let subtitle = ''
                        if(err.response) {
                            if(err.response.status == 401) {
                                subtitle = 'Invalid credentials'
                            }
                            else {
                                subtitle = err.response.data;
                            }
                        }
                        else if (err.request) {console.log(err.request);}
                        else {console.log('Error', err.message);}

                        commit('auth_error', err)
                        commit('error', {title:'Login failed',subtitle:subtitle});
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },

        logout({commit}) {
            console.log('store logout')
            localStorage.removeItem('token')
            commit('logout');
        }
    }
})

