<template>

    <div id="nav" class="sm:sticky sm:top-0 sm:z-10 bg-light-blue-500 dark:bg-gray-700">

        <header id="header" class="p-4 text-white flex flex-col space-y-4 items-center justify-between max-w-screen-xl m-auto
                                  sm:flex-row sm:pl-10 sm:pr-10 sm:space-y-0">
            <nav>
                <ul class="flex space-x-5 sm:space-x-10 m-auto">
                    <div class="">
                        <li>
                            <router-link to="/"><img src="/images/logo.svg"/></router-link>
                        </li>
                    </div>

                    <li class="hover:text-black dark:hover:text-cyan-500">
                        <router-link to="/about">About</router-link>
                    </li>

                    <li class="hover:text-black dark:hover:text-cyan-500">
                        <router-link to="/docs">Docs</router-link>
                    </li>

                    <li class="hover:text-black dark:hover:text-cyan-500">
                        <router-link to="/demo">Demo</router-link>
                    </li>

                    <li v-if="showSongsLink()" class="hover:text-black dark:hover:text-cyan-500">
                        <router-link to="/songs">My Songs</router-link>
                    </li>

                </ul>
            </nav>
            <nav>
                <ul class="flex items-center sm:my-0 m-auto space-x-5">
                    <li>
                        <DarkMode></DarkMode>
                    </li>

                    <template v-if="showLoginButtons()">
                    <li>
                        <router-link class="duration-300 border rounded-full py-2 px-4 hover:border-white hover:bg-white hover:text-black" to="/login">Login</router-link>
                    </li>
                    <li>
                        <router-link class="duration-300 border rounded-full py-2 px-4 bg-cyan-200 text-black border-cyan-200 hover:bg-cyan-100 hover:border-cyan-100" to="/signup">Signup</router-link>

                    </li>
                    </template>

                    <template v-if="showLogoutButtons()">
                        <li>
                            <a class="duration-300 py-2 px-6 rounded-full border hover:border-white hover:bg-white hover:text-black cursor-pointer" @click="logout()">Logout</a>
                        </li>
                    </template>

                </ul>
            </nav>
        </header>

    </div>


</template>

<script>
import DarkMode from './DarkMode.vue'


export default {
    name: "NavBar",
    inject: ['player', 'emitter'],
    components: {DarkMode},
    data() {
        return {

        }
    },

    computed: {
        currentUser() {
            return this.$store.state.user;
        }
    },

    mounted() {
        //this.turnMenuOff();
        this.emitter.on('router-page-loaded', this.onRouterPageLoaded);
    },

    unmounted() {
        this.emitter.off('router-page-loaded', this.onRouterPageLoaded);
    },

    methods: {
        onRouterPageLoaded() {
            //this.turnMenuOff();
        },

        showLoginButtons() {
            return !this.currentUser;
        },

        showLogoutButtons() {
            return this.currentUser;
        },

        showSongsLink() {
            return this.currentUser;
        },

        logout() {
            this.$store.dispatch('logout');
            this.$router.push('/');
        },

        toggleMenu() {
            let header = document.getElementById("header");

            if (header.classList.contains("hidden")) {
                header.classList.remove("hidden");
            } else {
                header.classList.add("hidden")
            }
        },
        turnMenuOff() {
            let header = document.getElementById("header");
            header.classList.add("hidden")
        },

      isMobile() {
        var check = false;
        (function (a) {
          if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4)))
            check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      },
    }
}
</script>

<style scoped>
header {

}

header > * {
}

header img {
    height: 30px;
}

nav, li {
    display: flex;
    align-items: center;
}

.nav-button {
    /*border: 1.5px solid currentColor;*/
    border-width: 1px;
    border-radius: 2em;
    margin-left: 1em;
    letter-spacing: 1px;
    padding: 0.55em 1.5em;
}

.nav-button-highlight {
    background: #0ee9d5;
    border-color: #0ee9d5;
}

</style>
