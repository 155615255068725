import {createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from './store'

import App from './App.vue'
import './index.css'
import mitt from 'mitt'
import * as Pixelsong from "@/player/index.js";

import Test from './views/Test.vue'
import Home from './views/Home.vue'
import Docs from './views/Docs.vue'
import About from './views/About.vue'
import Songs from './views/Songs.vue'
import Dashboard from './views/Dashboard.vue'
import Demo from './views/Demo.vue'
import Login from './views/Login.vue'
import Signup from './views/Signup.vue'



const emitter = mitt();

const axios = require('axios').default;
// axios.defaults.baseURL = 'http://localhost:7001/api/';


const routes = [
    { path: '/',      component: Home, meta: { title: 'Pixelsong - Generative music from your web traffic' } },
    { path: '/test', component: Test, meta: { title: 'Test' } },
    { path: '/docs',  component: Docs, meta: { title: 'Documentation' }},
    { path: '/about', component: About, meta: { title: 'About Pixelsong' }},
    { path: '/songs', component: Songs, meta: { title: 'My Songs' }},
    { path: '/dashboard/:songid', component: Dashboard, meta: { title: 'Song' }},
    { path: '/demo/', component: Demo, meta: { title: 'Pixelsong Demo' }},
    { path: '/signup', component: Signup, meta: { title: 'Signup | Pixelsong' }},
    { path: '/login', component: Login, meta: { title: 'Login | Pixelsong' }},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
})

router.beforeEach((to, from) => {
    store.dispatch('errorClear');
})

router.afterEach((to, from) => {
    emitter.emit('router-page-loaded');
    document.title = to.meta.title || 'Pixelsong';
})

// router.beforeEach((to, from, next) => {
//     window.scrollTo(0, 0);
// })


const app = createApp(App)
app.use(router)
app.use(store)
//store.dispatch('initialize');



// global var because we can't let this player object in the
// vue data store because it corrupts the audio context
app.provide('player', new Pixelsong.Player())
app.provide('emitter', emitter)
app.provide('axios', axios)
app.mount('#app')



// auto redirect to login
// router.beforeEach((to, from, next) => {
//     const publicPages = ['/login', '/register', '/home'];
//     const authRequired = !publicPages.includes(to.path);
//     const loggedIn = localStorage.getItem('user');
//
//     // trying to access a restricted page + not logged in
//     // redirect to login page
//     if (authRequired && !loggedIn) {
//         next('/login');
//     } else {
//         next();
//     }
// });
