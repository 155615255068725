<template>
    <div :id="containerId" class="sparkline flex items-end"></div>
</template>

<script>
import * as Pixelsong from "@/player/index.js";

var d3 = require("d3");
var d3i = require("d3-interpolate")
import mitt from 'mitt'


export default {
    name: "Sparkline",
    inject: ['player', 'emitter'],
    props: {
        entryId: String,
        subEntryId : String,
        containerId: String,
        psd2:Pixelsong.PsData2,
    },

    data() {
        return {
            width: 100,
            height: 20,
            x: d3.scaleLinear(),
            y: d3.scaleLinear(),
            g: null,
            line: null,
            style: {
                stroke: "rgb(14, 165, 233)",
                strokeWidth: 1
            },
            prevPrevVal: 0,
            prevVal: 0,
            curVal: 0,
        }
    },
    mounted() {
        const sparkline = (container, data) => {

            // Add an SVG object to the given container.
            let svg = d3
                .select(container)
                .append("svg")
                .classed("sparkline", true)
                .classed("sparkline-svg", true)
                .attr("width", this.width)
                .attr("height", this.height);

            this.g = svg
                .append("g")
                .classed("sparkline", true)
                .classed("sparkline-group", true);

            this.x.range([0, this.width]).domain(d3.extent(this.getData(), d => d.date));
            this.y.range([this.height, 0]).domain(d3.extent(this.getData(), d => d.value));

            //Create the line generator function.
            this.line = d3
                .line()
                .curve(d3.curveBasis)
                .x(d => this.x(d.date))
                .y(d => this.y(d.value));

            // Finally, draw the path object.
            let path = this.g
                .append("path")
                .classed("sparkline", true)
                .classed("sparkline-path", true)
                .data([data])
                .attr("d", this.line)
                .style("fill", "none")
                .style("stroke", this.style.stroke)
                .style("stroke-width", this.style.strokeWidth);
        }

        let myContainer = document.getElementById(this.containerId);
        const myData = this.psd2.sparklineData(this.entryId,this.subEntryId);
        sparkline(myContainer, myData);
        this.emitter.on('data-refresh', this.update);
    },

    methods: {
        getData() {
            const myData = this.psd2.sparklineData(this.entryId,this.subEntryId);
            return myData;
        },

        update(e) {

            const myData = this.psd2.sparklineData(this.entryId,this.subEntryId);
            this.x.range([0, this.width]).domain(d3.extent(this.getData(), d => d.date));
            this.y.range([this.height, 0]).domain(d3.extent(this.getData(), d => d.value));

            this.g.selectAll("path")
                .data([this.getData()])
                .join(
                    enter => enter.append("path")
                        .classed("sparkline", true)
                        .classed("sparkline-path", true),
                    update => update,
                    exit => exit.remove()
                )
                .attr("d", this.line)
                .style("fill", "none")
                .style("stroke", 'rgb(14, 165, 233)')
                .style("stroke-width", 1);
        }
    }
}
</script>

<style>
.sparkline svg {

}
</style>
