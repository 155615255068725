import { v4 as uuidv4 } from 'uuid';
var PsData2 = /** @class */ (function () {
    function PsData2() {
        console.log('creating psdata2');
        this.entries = new Array();
        this.subscriptions = new Array();
        this.refreshInterval = 1000;
        this.paths = new Array();
        this.titles = new Array();
        this.texts = new Array();
        this.ids = new Array();
        // static entries setup
        this.setup();
    }
    PsData2.prototype.setup = function () {
        this.addEntry('all', null);
        this.addEntry('pageload', null);
        this.addEntry('pageload.path', 'url');
        this.addEntry('pageload.title', 'title');
        this.addEntry('click', null);
        this.addEntry('click.path', 'url');
        this.addEntry('click.title', 'title');
        this.addEntry('click.text', 'text');
        this.addEntry('click.tag', 'tag');
        this.addEntry('click.id', 'target_id');
    };
    PsData2.prototype.all = function () { return this.entries['all']; };
    PsData2.prototype.pageloads = function () { return this.entries['pageload']; };
    PsData2.prototype.pageloadpaths = function () { return this.entries['pageload.path']; };
    PsData2.prototype.pageloadtitles = function () { return this.entries['pageload.title']; };
    PsData2.prototype.clicks = function () { return this.entries['click']; };
    PsData2.prototype.clickpaths = function () { return this.entries['click.path']; };
    PsData2.prototype.clicktitles = function () { return this.entries['click.title']; };
    PsData2.prototype.clicktexts = function () { return this.entries['click.text']; };
    PsData2.prototype.clicktags = function () { return this.entries['click.tag']; };
    PsData2.prototype.clickids = function () { return this.entries['click.id']; };
    PsData2.prototype.sparklineData = function (entryId, subEntryId) {
        var dd = this.entries[entryId].history;
        if (subEntryId) {
            var sdd = this.entries[entryId].subEntries[subEntryId].history;
            return sdd;
        }
        else {
            return dd;
        }
    };
    PsData2.prototype.clear = function () {
        this.setup();
    };
    PsData2.prototype.onEvent = function (e) {
        //console.log('onEvent',e);
        this.feed(e);
        this.subscriptions.forEach(function (sub) {
            if (sub.matches(e)) {
                sub.onEvent(e);
            }
        });
    };
    // -------------
    // data refresh
    // -------------
    PsData2.prototype.refresh = function () {
        var _this = this;
        Object.keys(this.entries).forEach(function (key) {
            var each = _this.entries[key];
            each.refresh();
        });
    };
    // ---------------
    // data entry feed
    // ---------------
    PsData2.prototype.feed = function (e) {
        this.entries['all'].onEvent(e);
        if (e.name == 'pageload') {
            this.entries['pageload'].onEvent(e);
            if (e.url)
                this.entries['pageload.path'].onEvent(e);
            if (e.title)
                this.entries['pageload.title'].onEvent(e);
        }
        else if (e.name == 'click') {
            this.entries['click'].onEvent(e);
            if (e.url)
                this.entries['click.path'].onEvent(e);
            if (e.title)
                this.entries['click.title'].onEvent(e);
            if (e.text)
                this.entries['click.text'].onEvent(e);
            if (e.tag)
                this.entries['click.tag'].onEvent(e);
            if (e.id)
                this.entries['click.id'].onEvent(e);
        }
        if (e.url && !this.paths.includes(e.url)) {
            this.paths.push(e.url);
            this.paths.sort();
        }
        if (e.title && !this.titles.includes(e.title)) {
            this.titles.push(e.title);
            this.titles.sort();
        }
        if (e.text && !this.texts.includes(e.text)) {
            this.texts.push(e.text);
            this.texts.sort();
        }
        if (e.target_id && !this.ids.includes(e.target_id)) {
            this.ids.push(e.target_id);
            this.ids.sort();
        }
    };
    PsData2.prototype.addEntry = function (name, subEntryProperty) {
        this.entries[name] = new Entry(name, 'pretty', 0, this.refreshInterval, subEntryProperty);
    };
    // -------------
    // subscriptions
    // -------------
    PsData2.prototype.subscribe = function (sub) {
        this.subscriptions.push(sub);
    };
    PsData2.prototype.unsubscribe = function (sub) {
        for (var i = 0; i < this.subscriptions.length; i++) {
            if (this.subscriptions[i].myId == sub.myId) {
                this.subscriptions.splice(i, 1);
            }
        }
    };
    return PsData2;
}());
export { PsData2 };
var Subscription = /** @class */ (function () {
    function Subscription(name, url, tag, id, text, title) {
        this.myId = uuidv4().toString();
        this.name = name;
        this.url = url;
        this.tag = tag;
        this.id = id;
        this.text = text;
        this.title = title;
    }
    Subscription.prototype.matches = function (e) {
        //console.log('matching',this,e)
        // Check event name
        if (this.name && this.name != 'all' && this.name != e.name) {
            //console.log('name mismatch')
            return false;
        }
        // Check event url
        if (this.url && !this.patternMatches(this.url, e.url)) {
            //console.log('url mismatch',this.url,e.url)
            return false;
        }
        // Check page title
        if (this.title && !this.patternMatches(this.title, e.title)) {
            //console.log('title mismatch',this.title,e.title)
            return false;
        }
        // Check element tag
        if (this.tag && this.tag != 'all' && this.tag != e.tag) {
            //console.log('tag mismatch',this.tag,e.tag)
            return false;
        }
        // Check element id
        if (this.id && this.id != e.target_id) {
            //console.log('id mismatch',this.id,e.target_id);
            return false;
        }
        // Check element text
        if (this.text && this.text != e.text) {
            //console.log('text mismatch',this.text,e.text)
            return false;
        }
        // no quick exits for false so it must match
        return true;
    };
    Subscription.prototype.describe = function () {
        var desc = '';
        desc += this.name;
        if ('all' == this.name) {
            return 'All events';
        }
        else if ('pageload' == this.name) {
            if (this.url) {
                desc += ' [' + this.url + ']';
            }
            else if (this.title) {
                desc += ' [' + this.title + ']';
            }
        }
        else if ('click' == this.name) {
            var inner = '';
            if (this.tag != 'all') {
                inner += this.tag + ':';
            }
            if (this.id) {
                inner += this.id;
            }
            else if (this.text) {
                inner += this.text;
            }
            desc += '[' + inner + ']';
        }
        if ('pageload' == this.name) {
            if (this.url && this.title)
                desc += ' +';
        }
        else if ('click' == this.name) {
            if (this.url || this.title || (this.id && this.text))
                desc += ' +';
        }
        return desc;
    };
    Subscription.prototype.details = function () {
        var details = '';
        details += this.name;
        if (this.url)
            details += ' url [' + this.url + ']';
        if (this.tag)
            details += ' tag [' + this.tag + ']';
        if (this.id)
            details += ' id [' + this.id + ']';
        if (this.text)
            details += ' text [' + this.text + ']';
        if (this.title)
            details += ' title [' + this.title + ']';
        return details;
    };
    Subscription.prototype.patternMatches = function (pattern, toCheck) {
        if (pattern.includes('*')) {
            var regex = pattern.replace('*', '.+?');
            return toCheck.match(regex) != null;
        }
        else {
            return pattern == toCheck;
        }
    };
    Subscription.prototype.urlMatches = function (url) {
        if (this.url.includes('*')) {
            var regex = this.url.replace('*', '.+?');
            return url.match(regex) != null;
        }
        else {
            return this.url == url;
        }
    };
    return Subscription;
}());
export { Subscription };
var Event = /** @class */ (function () {
    function Event() {
    }
    return Event;
}());
export { Event };
var Entry = /** @class */ (function () {
    function Entry(name, pretty, count, refreshInterval, subEntryProperty) {
        this.name = name;
        this.count = count;
        this.pretty = pretty;
        this.perSecond = 0;
        this.lastRefresh = Date.now();
        this.lastCount = 0;
        this.perSecondPretty = "0.00";
        this.refreshInterval = refreshInterval;
        this.historySeconds = 10;
        this.history = [];
        this.historyAvg = [];
        this.maxHistory = this.historySeconds * (1000 / this.refreshInterval); // num seconds * interval as a fraction of a second
        this.highWaterMark = 0;
        this.subEntries = {};
        this.subEntryProperty = subEntryProperty;
        // starting history of zeros to match the granularity based on the looping interval
        for (var i = this.maxHistory; i > 0; i--) {
            this.history.push({
                date: new Date(Date.now() - (i * (this.historySeconds * 1000) / this.maxHistory)),
                value: 0,
                highWaterMark: this.highWaterMark
            });
            this.historyAvg.push({
                date: new Date(Date.now() - (i * (this.historySeconds * 1000) / this.maxHistory)),
                value: 0,
                highWaterMark: this.highWaterMark
            });
        }
    }
    Entry.prototype.sortedSubEntries = function () {
        var _this = this;
        var sorted = Array();
        Object.keys(this.subEntries).forEach(function (each) {
            sorted.push(_this.subEntries[each]);
        });
        sorted = sorted.sort(function (a, b) {
            return b.count - a.count;
        });
        return sorted;
    };
    Entry.prototype.onEvent = function (e) {
        //console.log('entry onEvent',this.name)
        this.count++;
        // if this entry was designed to have sub-entries, let's add them to the list
        // and keep them updated with the refresh interval for sparklines
        if (this.subEntryProperty) {
            this.subfeed(e);
        }
    };
    Entry.prototype.subfeed = function (e) {
        if (this.subEntryProperty) {
            var prop = e[this.subEntryProperty];
            var subEntry = this.subEntries[e[this.subEntryProperty]];
            if (!subEntry) {
                subEntry = new Entry(prop, 'pretty', 0, this.refreshInterval, null);
                this.subEntries[e[this.subEntryProperty]] = subEntry;
            }
            subEntry.onEvent(e);
        }
    };
    Entry.prototype.refresh = function () {
        var _this = this;
        var diffTimeMillis = Date.now() - this.lastRefresh;
        var diffCount = this.count - this.lastCount;
        this.perSecond = (diffCount / (diffTimeMillis / 1000));
        this.perSecondPretty = this.perSecond.toPrecision(2);
        this.lastRefresh = Date.now();
        this.lastCount = this.count;
        this.updateHistory(new Date(), this.perSecond);
        Object.keys(this.subEntries).forEach(function (key) {
            _this.subEntries[key].refresh();
        });
    };
    Entry.prototype.updateHistory = function (date, value) {
        var he = new HistoryItem(date, value, this.highWaterMark);
        this.history.push(he);
        if (this.history.length > this.maxHistory) {
            this.history.shift(); // removes 1st element
        }
        if (value > this.highWaterMark) {
            this.highWaterMark = value;
        }
    };
    Entry.prototype.getHistoryAverage = function () {
        var window = 5;
        var wa = 0;
        var avg = 0;
        var count = 0;
        this.historyAvg = [];
        for (var i = 0; i < this.history.length; i++) {
            var item = this.history[i];
            avg = 0;
            count = 0;
            wa = Math.min(i, window);
            for (var j = 0; j < wa; j++) {
                avg += this.history[i - j].value;
                count++;
            }
            avg = avg / count;
            this.historyAvg.push(new HistoryItem(item.date, avg, 0));
        }
        if (this.name == 'all') {
            console.log('avg', this.historyAvg);
        }
        return this.historyAvg;
    };
    return Entry;
}());
export { Entry };
var HistoryItem = /** @class */ (function () {
    function HistoryItem(date, value, highWaterMark) {
        this.date = date;
        this.value = value;
        this.highWaterMark = highWaterMark;
    }
    return HistoryItem;
}());
export { HistoryItem };
